export default class BaseObject{
    /**
     *
     * @param {Phaser.Types.Tilemaps.TiledObject} props
     * @param {TableauPhaser} tableau
     */
    constructor(props,tableau) {
        /**
         * Les propriétés récupérées depuis tiled
         * @type  {Phaser.Types.Tilemaps.TiledObject}
         */
        this.props=props;
        /**
         * Objet graphique principal
         * @type {null|Phaser.Types.Physics.Arcade.ImageWithDynamicBody}
         */
        this.displayObject=null;
        /**
         *
         * @type {TableauPhaser}
         */
        this.tableau=tableau;
        /**
         * A été construit ou non?
         * @type {boolean}
         */
        this.builded=false;
        /**
         * Si l'objet est mort ou non
         * @type {boolean}
         */
        this.isDead=false;
        /**
         * Là où est inséré le display object dans la scene
         * @type {Phaser.GameObjects.Container}
         */
        this.container=this.tableau.plan2Objects;
        this.group=this.tableau.add.container(this.props.x,this.props.y);
        this.container.add(this.group);
        /**
         *
         * @type {string}
         */
        this.id="obj-"+Math.random();

    }

    /**
     * Construit les displkay objects
     */
    buildDisplay(){
        if(!this.builded){
            /**
             *
             * @type {Phaser.Types.Physics.Arcade.ImageWithDynamicBody}
             */
            this.displayObject=this.tableau.physics.add.image(0,0,this.props.name);
            this.displayObject.setOffset(0,0);
            this.displayObject.setOrigin(0,1);
            this.displayObject.body.allowGravity=false;
            this.group.add(this.displayObject);
            this.builded=true;
        }
    }

    /**
     * lance les boucles tween etc
     */
    play(){
        if(this.isDead){
            return;
        }
        this.playing=true;
        if(!this.builded){
            this.buildDisplay();
        }
    }
    /**
     * l'inverse de play
     */
    stop(){
        this.playing=false;
    }

    /**
     * Tue l'objet et il ne pourra pas être réutilisé ensuite
     */
    die(){
        this.isDead=true;
        this.displayObject.disableBody(true,true);
        this.stop();
        this.displayObject.destroy();
        this.group.destroy();
    }





}