<template>
<div class="modal-game">
  <btn-relief
      @click.native="$manager.displayModal='';"
      class="close circle">
    <v-icon color="var(--color-bleu-bca)">mdi-close</v-icon>
  </btn-relief>
  <slot></slot>
</div>
</template>

<script>
import BtnRelief from "@/ui/btn-relief";
export default {
  name: "modal-game",
  components: {BtnRelief},
  props:{
    toggler:{
      type:String,
    }
  }

}
</script>

<style lang="less">
  .modal-game{

    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: fixed;

    --w:1000px;
    --h:600px;
    border-radius: 80px;
    min-width: var(--w);
    min-height: var(--h);
    height: 900px;
    width: 1200px;
    max-height: 90vh;
    max-width: 90vw;
    border: 50px solid #fff;
    box-sizing: border-box;
    color: #fff;
    background: linear-gradient(180deg, rgba(0,54,79,1) 0%, rgba(10,100,142,1) 100%);
    box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.2),
    10px 10px 30px 0px rgba(0, 0, 0, 0.2);

    ::-webkit-scrollbar {
      width: 8px;
      height: 3px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(200, 200, 200, 0.5);
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(255, 0, 0, 0);

    }

  }

  .scrollable-content{
    min-height: calc(100%);
    padding: 30px;
    padding-top: 60px;
    overflow-y: scroll;
    height: 100%;
    width: calc(100% + 15px);
  }


  .modal-game{
    .close{
      position: absolute;
      right: -50px;
      top: -50px;
      z-index: 2;
    }
  }


</style>