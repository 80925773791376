<template>
  <modal-game
      toggler="displayScores"
      class="tableau-scores">
    <!--
    <div class="bar">
      <btn-relief v-for="unite of unites"
                  :key="unite"
                  @click.native="filter=unite;"
                  :class="{'active':unite===filter}"
      >
        {{unite.toUpperCase()}}
      </btn-relief>
    </div>
    -->
    <div class="content">
      <div class="podium">
        <template v-for="(equipe,i) of all">
          <div
              v-if="i<=2 && equipe.points>0"
              class="equipe-podium"
              :key="equipe.uid"
              :class="{
               'is-current':equipe.uid===$manager.equipe.uid
             }">
            <etoile-score :flamme="equipe.pos===1">{{equipe.pos}}</etoile-score>
            <span class="name">
              {{equipe.name}}
              <div style="opacity: 0.5;font-size: 75%;margin-top: -6px;">
                  <!--{{equipe.geo}} | {{equipe.unite}} | {{equipe.numero}}-->
              </div>
            </span>
            <span class="points">{{equipe.pointsFormated}}</span>
          </div>
        </template>
      </div>
      <div class="liste scrollable-content"
           :class="{
          'no-scroll':!hasScroll
         }"
           ref="liste">
        <div class="liste-cols">
          <template v-for="(equipe,i) of all">
            <div
                v-if="i>2 || equipe.points===0"
                class="equipe-line"
                :key="equipe.uid"
                :class="{'is-current':equipe.uid===$manager.equipe.uid}">
              <span class="position">{{equipe.pos}}</span>
              <span class="name">
                {{equipe.name}}<br>
                <div style="opacity: 0.5;font-size: 80%;margin-top: -2px">
                  <!--{{equipe.geo}} | {{equipe.unite}} | {{equipe.numero}}-->
                </div>

              </span>
              <span class="dots"/>
              <span class="points">{{equipe.pointsFormated}}</span>
            </div>
          </template>
        </div>

      </div>
    </div>
  </modal-game>
</template>

<script>
import EtoileScore from "@/ui/etoile-score";
//import BtnRelief from "@/ui/btn-relief";
import ModalGame from "@/ui/modal-game";
export default {
  name: "tableau-scores",
  components: {
    ModalGame,
    //BtnRelief,
    EtoileScore},
  data(){
    return{
      filter:'bca',
      hasScroll:true,
      unites:[
          "bca",
          "experts",
          "c.client",
      ]
    }
  },
  mounted(){
    window.$manager.loadEquipes();
    this.applyScrollOrNot();
  },
  computed:{
    all(){
      let liste;
      switch (this.filter){
        case "bca":
          liste=window.$manager.listeEquipes;
          break;
        case "experts":
          liste=window.$manager.listeEquipes.filter(it=>it.unite==="experts" || it.unite==="use");
          break;
        case "c.client":
          liste=window.$manager.listeEquipes.filter(it=>
              it.unite==="usc réparation" ||
              it.unite==="usc pt" ||
              it.unite==="upc" ||
              it.unite==="usc rcp pj"
          );
          break;
      }

      liste=liste.sort(
          function(a,b){
            return b.points-a.points;
          }
      )

      let pos=0;
      let points=0;
      for(let e of liste){
        if(e.points !== points){
          points=e.points;
          pos++;
        }
        e.pos=pos;
      }


      return liste;
    }
  },
  watch:{
    filter(){
      this.applyScrollOrNot();
    }
  },
  methods:{
    applyScrollOrNot(){
      let me=this;
      setTimeout(function(){
        me.hasScroll=me.$refs.liste.scrollHeight > me.$refs.liste.clientHeight
      },1);
    }
  }
}
</script>

<style lang="less">
  .tableau-scores{

    .bar{
      z-index: 2;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      top: -40px;
      >*{
        margin: 0px 15px;
      }
    }
    .content{
      //outline: 1px solid red;
      min-height: 100%;
      width: 100%;
      >*{
        //outline: 1px solid yellow;
        position: absolute;
        top: 1px;
        height: 100%;
      }
      >.podium{
        width: 30%;
      }
      >.liste{
        left: 30%;
        width: calc(70% + 15px);
        &.no-scroll{
          display: flex;
          flex-direction: column;
          justify-content: center;
        }




        >.liste-cols{
          flex-grow: 0;
          user-select: none;
          display: flex;
          flex-wrap: wrap;
          >*{
            width: 50%;
          }
        }
      }

    }
    .podium{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      >*{
        margin: 30px;
        &:nth-child(2){
          transform: scale(0.8);
        }
        &:nth-child(3){
          transform: scale(0.65);
        }
      }
    }
    @import "./scores/equipe-podium";
    @import "./scores/equipe-line";



  }
</style>