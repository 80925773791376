import { render, staticRenderFns } from "./ui-score.vue?vue&type=template&id=ff9c7570&"
import script from "./ui-score.vue?vue&type=script&lang=js&"
export * from "./ui-score.vue?vue&type=script&lang=js&"
import style0 from "./ui-score.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VProgressLinear,VSwitch})
