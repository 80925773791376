<template>
  <v-card
      :key="$manager.mapPinInfos.titre"
      class="map-text animate__animated animate__fadeInUp"
      style="
    position: absolute;
    right: -30px;
    top: 80px;
    width: 300px;
    padding: 20px 0px 0 20px;
    "
          rounded="xl"
      elevation="10"
  >
    <img src="game/pointeur.png"
         alt=""
         class="animate__animated animate__bounceInDown"
         style="
         position: absolute;
         width: 50px;
         left:-20px;
         top:-20px;
    "/>
    <v-card-title class="txt-bold"
    style="color: var(--color-blue-bca)"
    >
      {{$manager.mapPinInfos.titre}}
    </v-card-title>
    <v-card-text>
      <div class="txt-bold"
           v-html="$manager.mapPinInfos.texte"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>

      <btn-relief
          v-if="$manager.mapPinInfos.isCurrent"
          style="
          pointer-events: auto"
          @click.native="$manager.startGame()">
        Jouer !
      </btn-relief>
    </v-card-actions>
  </v-card>
</template>

<script>
import BtnRelief from "@/ui/btn-relief";
export default {
  name: "map-card",
  components: {BtnRelief},
  computed:{
    titre(){
      return this.$manager.mapPinInfos.titre;
    }
  }
}
</script>

<style scoped>

</style>