<template>
<div class="choix-personnage">

  <anim-textes @ended="finish" :pause-end="true">
    <div class="slide">
      <div class="phrase">
        Choisissez
        <small>votre</small>
      </div>
      <div class="phrase">
        <div class="y">personnage</div>
      </div>
    </div>
  </anim-textes>

  <div class="d-flex choix" v-if="displayButtons">
    <div @click="$manager.personnage='femme'" class="perso animate__animated animate__flipInY">
      <img src="choix-perso/femme.png" alt="">
      <btn-check :active="true" class="elevation-5"/>
    </div>
    <div @click="$manager.personnage='homme'" class="perso animate__animated animate__flipInY" style="animation-delay: 0.2s;">
      <img src="choix-perso/homme.png" alt="">
      <btn-check :active="true" class="elevation-5"/>
    </div>

  </div>

</div>
</template>

<script>
import BtnCheck from "@/ui/btn-check";
import AnimTextes from "@/ui/anim-textes";
export default {
  name: "choix-personnage",
  components: {AnimTextes, BtnCheck},
  data(){
    return{
      displayButtons:false
    }
  },
  methods:{
    finish(){
      console.log("hop")
      this.displayButtons=true;
    }
  }
}
</script>

<style lang="less">
 .choix-personnage{
   pointer-events: auto;
   width: 100%;
   height: 100%;
   border-radius: 40px;
   background-color: #09638D;
   background-image: url('/choix-perso/fond.jpg');
   background-size: cover;
   .anim-textes{
     top: -20%;
     left: 10%;
   }
   .choix{
     position: absolute;
     width: 50%;
     height: 50%;
     right: 0;
     bottom: 0;
     justify-content: center;
     align-items: center;
     >*{
       flex-grow: 0;
     }
   }
   .perso{
     cursor: pointer;
     transition: all 0.2s;
     position: relative;
     filter:  brightness(0.9);
     .btn-check{
       border: 6px solid #fff;
       border-radius: 100%;
       position: absolute;
       left: 50%;
       bottom: -5%;
       transition: all 0.25s;
       transform: translateX(-50%) scale(0.9);
       opacity: 0;
     }
     >img{
       transition: all 0.5s;
       transform: scale(0.9);
     }
     &:hover{
       filter:  brightness(1);
       >img{
         transform: scale(1);
       }
       .btn-check {
         opacity: 1;
         transform: translateX(-50%) scale(1);
       }
     }
   }
 }

</style>