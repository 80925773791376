<template>
<button
    class="btn-check"
    :class="{
        disabled:!active,
        enabled:active,
    }"
>

</button>
</template>

<script>
export default {
  name: "btn-check",
  props:{
    active:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="less">
.btn-check{
  //outline: 1px solid red;
  width: 70px;
  height: 70px;
  background-image: url("circle-check.svg");
  background-size: contain;
  cursor: pointer;
  transition: all 0.2s;
  &.disabled{
    filter:saturate(0);
    cursor: not-allowed;
  }
  &.enabled{
    &:hover{
      filter:brightness(0.95);
    }
    &:active{
      filter:brightness(1.1);
    }
  }

  outline: none !important;
}
</style>