<template>
<div class="question-blocks">
    <bulle-question
        :question="question"
        :time="chronoString"
        :texte="texteBulleQuestion"
        :indication="indicationBulleQuestion"
    />

  <div v-if="displayBulleReponses">
    <div v-for="r of question.reponses" :key="r.abcde"
         class="reponse"
         :class="{
            'checked':r.checked
         }"
         :style="{
      position:'absolute',
      left:'calc('+r.gamePosition.x+' * var(--scale-factor-rev) * 1px)',
      top:'calc('+r.gamePosition.y+' * var(--scale-factor-rev) * 1px)'



    }"
    >
      {{r.abcde.toUpperCase()+" - "+r.texte}}
    </div>
  </div>


</div>
</template>

<script>
import BulleQuestion from "@/game/questions/components/bulle-question";
import QuestionMixin from "@/game/questions/QuestionMixin";
export default {
  name: "question-blocks",
  components: { BulleQuestion},
  mixins:[QuestionMixin],
  computed:{

  }
}
</script>

<style lang="less">
.question-blocks{
  .bulle-question{
    //outline: 1px solid red;
    position: absolute;
    max-width: 70%;
    right: calc( var(--scale-factor) * 120px );
    top: calc( var(--scale-factor) * 50px )
  }
  .reponse{
    position: absolute;
    //background-color: #fff;
    width: calc( var(--scale-factor) * 220px);
    height: calc( var(--scale-factor) * 96px);
    //border-radius: 8px;
    padding: 13px;
    padding-left: 25px;

    //outline: 1px solid red;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-normal);
    font-weight: bold;
    font-size: calc( 14px * var(--scale-factor) ) ;
    color: var(--color-blue-dark);
    //box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.16);
    &.checked{
      color: var(--color-yellow);
    }
  }
}
</style>