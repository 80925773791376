<template>
<div>
  <v-form @submit.prevent="login">
    <v-select
        v-model="equipeUid"
        placeholder="Votre équipe"
        :items="equipesItems"
    />
    <v-text-field
        class="pwd"
      placeholder="mot de passe"
      v-model="pwd"
    />
    <v-alert color="error" dense
             class="my-5"
             outlined
             v-if="error">
      {{error}}
    </v-alert>
    <div class="d-flex justify-end my-5">
      <v-btn
          color="var(--color-yellow)" dark
          :disabled="!valid"
          type="submit">Connexion</v-btn>
    </div>
    <pre>{{gameData}}</pre>
  </v-form>

</div>
</template>

<script>
export default {
  name: "login-equipe",
  data () {
    return {
      equipesItems:[],
      equipeUid:"",
      pwd:"",
      error:"",
      gameData:"",
    }
  },
  mounted() {
    if(this.$api.equipeUid){
      this.equipeUid=this.$api.equipeUid;
      //this.login();
    }
    this.fillList();
  },
  watch:{
    equipes(){
      this.fillList();
    }
  },
  computed:{
    /**
     *
     * @return {Equipe[]}
     */
    equipes(){
      return this.$manager.listeEquipes;
    },
    valid(){
      return this.equipeUid!=='' && this.pwd.length>3;
    }
  },
  methods:{
    fillList(){
      for(let e of this.equipes){
        this.equipesItems.push({
          text:`${e.numero} - ${e.name}`,
          value:e.uid,
        })
      }
      this.equipesItems=this.equipesItems.sort(function(a,b){return a.numero<b.numero?"-1":"1"})
    },
    login(){
      let me=this;
      me.error="";
      console.log("login");
      me.$api.loginEquipe(
          me.equipeUid,me.pwd,
          function(data){
            me.$manager.initPingEquipe();
            me.$manager.initTableauxForEquipe(data.body.tableaux)
        },
          function(err){
          me.error=err.errors[0].message;
        }
      )
    }
  }
}
</script>

<style lang="less">
  .pwd{
    input{
      -webkit-text-security:disc;
    }

  }
</style>