export default class DebugConfig{
    constructor() {
        /**
         * Si true affichera les rectangles de debug dans Phaser
         * @type {boolean}
         * @default false
         */
        this.arcadePhysics=false;
        /**
         * Si false les question ne s'ouvrent pas et on peu se balader dans tout le niveau
         * @type {boolean}
         * @default true
         */
        this.sectionsActive=true;
        /**
         * Si false le jeu ne passe pas en full screen au click
         * @type {boolean}
         * @default true
         */
        this.fullScreenEnabled=String(document.location.host).match(/olympiades-bca/) !== null;
        /**
         * Si true affiche les data des questions
         * @type {boolean}
         * @default false
         */
        this.debugQuestions=false;
    }

}