import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Manager from "@/Manager";
import Api from "@/Api";

Vue.config.productionTip = false;


window.$manager=new Manager();
Vue.prototype.$manager = Vue.observable(window.$manager);
///window.$api=new Api("http://bca-town-api.local/api");
let apiUrl="https://api.olympiades-bca.fr/api";
//let apiUrl="";

if(document.location.hostname !== "olympiades-bca.fr"){
  alert("Vous êtes sur la version de test "+window.$manager.projectDesc);
  apiUrl="https://dev-api.olympiades-bca.fr/api";
  window.$manager.debug.debugQuestions=true;
  //////apiUrl="http://bca-town-api.local/api";
}
window.$api=new Api(apiUrl);
Vue.prototype.$api = Vue.observable(window.$api);

window.$manager.loadEquipes();

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');