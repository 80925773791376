
import BaseObject from "@/game/Objets/BaseObject";
import BullePNJ from "@/game/Objets/BullePNJ";

export default class PNJ extends BaseObject
{
    constructor(props,tableau)
    {
        super(props,tableau);
        this.tween=null;
        /**
         * @type {TableauPhaser}
         */
        this.tableau = tableau;
        this.id=this.id+props.name;
        this.buildDisplay();
    }

    buildDisplay() {
        super.buildDisplay();
    }

    play() {
        super.play();
        // eslint-disable-next-line no-unused-vars
        let me=this;
        if(!this.tween) {
            this.tween = this.tableau.tweens.add({
                targets: this.displayObject,
                x: {
                    duration: (Math.random() + 0.7) * 1500,
                    ease: 'Linear',
                    yoyo: -1,
                    repeat: -1,
                    flipX: true,
                },
            });
        }
        if(this.tween.paused){
            this.tween.resume();
        }
        // bulle
        this._createBulle();
        this.loop=setInterval(function(){
            if(!me.tableau.cameras.main){
                me.stop();
            }else{
                me.bullePnj.x=me.props.x-me.tableau.cameras.main.scrollX;
                me.bullePnj.y=me.props.y-me.tableau.cameras.main.scrollY;
            }
        },10)


    }

    stop(){
        console.log("stop pnj 1",this.id)
       if(this.tween){
           this.tween.pause();
       }
        if(this.loop){
            clearInterval(this.loop);
        }
        this._trashBulle();
    }
    _createBulle(){
        //console.log("_createBulle",this.id);
        let me=this;
        let existing=window.$manager.bullesPNJ.find(it=>it.pnjId===me.id);
        if(existing){
            this.bullePnj=existing;
        }else{
            this.bullePnj=new BullePNJ(this.id);
            window.$manager.bullesPNJ.push(this.bullePnj);
        }
    }
    _trashBulle(){
        //console.log("_trashBulle",this.id);
        let me=this;
        if(this.bullePnj){
            window.$manager.bullesPNJ=window.$manager.bullesPNJ.filter(
                function(it){
                    return it.pnjId !== me.id
                }
            );
        }
    }

}