import BaseObject from "@/game/Objets/BaseObject";
// eslint-disable-next-line no-unused-vars
import Phaser from "phaser";
export default class BlockReponse extends BaseObject
{
    /**
     *
     * @param props
     * @param tableau
     * @param {Reponse} reponse
     */
    constructor(props,tableau,reponse)
    {
        super(props,tableau);
        this.reponse=reponse;
    }
    buildDisplay() {
        if(!this.builded){
            let me=this;
            this.displayObject=this.tableau.physics.add.image(this.props.x,this.props.y,"block");
            this.displayObject.setOffset(0,0);
            this.displayObject.setOrigin(0,1);
            this.displayObject.setImmovable(true);
            this.displayObject.body.allowGravity=false;
            this.container.add(this.displayObject);
            this.builded=true;

            this.tableau.physics.add.collider(
                this.displayObject,
                this.tableau.player,
                function(){
                    me.reponse.checked=!me.reponse.checked;
                    me.tableau.soundDing();
                }
            );
            this.reponse.gamePosition.x=this.props.x-this.tableau.cameras.main.scrollX;
            this.reponse.gamePosition.y=this.props.y-this.tableau.cameras.main.scrollY-3*32;

            this.displayObject.alpha=0;
            this.tableau.tweens.add({
                targets: this.displayObject,
                alpha: {
                    from: 0,
                    to: 1,
                    duration: 1000,
                    ease: Phaser.Math.Easing.Sine.Out,
                    repeat:0,
                }
            });


        }
    }

    play(){
        super.play();
        let me=this;

        this.loop=setInterval(function(){
            if(!me.tableau.cameras.main){
                me.stop();
            }else{
                me.reponse.gamePosition.x=me.props.x-me.tableau.cameras.main.scrollX;
                me.reponse.gamePosition.y=me.props.y-me.tableau.cameras.main.scrollY-3*32;
            }
        },100)
    }
    stop(){
        super.stop();
        if(this.loop){
            clearInterval(this.loop);
        }
    }

    destroy(){
        this.display.disableBody(true,true)
    }



}