import Phaser from "phaser";
export default class Player extends Phaser.Physics.Arcade.Sprite
{
    constructor(scene, x, y)
    {
        super(scene, x, y, "player");
        console.log("player",window.$manager.personnage)

        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.setOrigin(0,1);
        this.setCollideWorldBounds(true);
        this.setBounce(0);
        this.setGravityY(700);
        this.setMaxVelocity(400, 800);
        this.setFriction(1, 1);

        let paddingX=120;
        let paddingY=20;
        //paddingY=0;
        this.setBodySize(this.body.width -paddingX, this.body.height -paddingY);
        this.setOffset(paddingX/2,paddingY/2);

        this.scene = scene;
        this.staticY = false;
        this._stopped=true;

        scene.anims.create(
            {
                key : 'stance',
                repeat: -0,
                frameRate:0.25,
                frames: scene.anims.generateFrameNames("player", {
                    prefix: window.$manager.personnage,
                    flipX:false,
                    start: 0,
                    end: 1,
                    zeroPad: 4
                })
            }
        );

        scene.anims.create({
            key: 'right',
            repeat: -1,
            frameRate:40,
            frames: scene.anims.generateFrameNames("player", {
                prefix: window.$manager.personnage,
                start: 2,
                end: 27,
                zeroPad: 4,
            })
        });


        scene.anims.create({
            key: 'jump-walk',
            repeat: -1,
            frameRate:15,
            frames: scene.anims.generateFrameNames("player", {
                prefix: window.$manager.personnage,
                start: 28,
                end: 28,
                zeroPad: 4,
            })
        });

        scene.anims.create({
            key: 'jump-stance',
            repeat: -1,
            frameRate:15,
            frames: scene.anims.generateFrameNames("player", {
                prefix: window.$manager.personnage,
                start: 29,
                end: 29,
                zeroPad: 4,
            })
        });

        this._directionX=0;
        this._directionY=0;
    }

    /**
     * arrête le joueur
     */
    stop()
    {
        if(this._stopped){
            return;
        }
        this._stopped=true;
        this.anims.play('stance', true);
        this.isRunning = false;
        this.setFlipX(false);
        this.setVelocityX(0);
        this.setVelocityY(0);
        this.directionY=0;
        this.directionX=0;
    }



    // eslint-disable-next-line no-unused-vars
    move(time, delta)
    {
        //let tick=delta/(1000/window.game.config.fps.target);
        //console.log(tick,delta)


        //bloqué en fonction des questions?
        if(!window.$manager.playerCanMove){
            return;
        }
        let s=window.$manager.currentSection;
        let q=window.$manager.currentQuestion;
        //ok, on bouge
        switch (true)
        {


            case this._directionX < 0 : // va vers la gauche et n'est pas bloqué à gauche
                //on va vérifier si on peut bien reculer
                if(s){
                    if(q && q.isDisplayBlocks && q.isActive){
                        if(this.getBounds().left<s.displayObject.getBounds().left){
                            //console.log("valider la question avant");
                            this.setVelocityX(0);
                            this.anims.play('stance', true);
                            return;
                        }
                    }
                }
                this.setVelocityX(-250);
                this.anims.play('right', true);
                this.setFlipX(true);
                break;

            case this._directionX > 0 : // va vers la droite et n'est pas bloqué à droite
                //on va vérifier si on peut bien avancer
                if(s){
                    if(q && q.isDisplayBlocks && q.isActive){
                        if(this.getBounds().right>s.displayObject.getBounds().right){
                            //console.log("valider la question avant");
                            this.setVelocityX(0);
                            this.anims.play('stance', true);
                            return;
                        }
                    }
                }
                this.setVelocityX(250);
                this.anims.play('right', true);
                this.setFlipX(false);
                break;

            default:
                this.setVelocityX(0);
                this.anims.play('stance', true);
        }

        //en stance sans mouvement on flippe pas le perso
        if(this.body.velocity.x===0 && this.body.velocity.y===0){
            this.setFlipX(false);
        }

        //en train de sauter?
        if(!this.body.blocked.down || this.body.velocity.y !== 0){
            if(this.body.velocity.x !== 0){
                this.anims.play('jump-walk', true);
            }else{
                this.anims.play('jump-stance', true);
            }
        }

        //saut
        if(this._directionY !== 0){
            if(this.body.blocked.down){
                this.setVelocityY(-700);
            }
        }else {
            this.staticY = true;
        }
    }


    get directionX(){return this._directionX;}
    get directionY(){return this._directionY;}

    set directionX(value){
        this._directionX=value;
        if(Math.abs(value)!==0){
            this._stopped=false;
        }
    }
    set directionY(value){
        this._directionY=value;
        if(Math.abs(value)!==0){
            this._stopped=false;
        }
    }
}