<template>
  <v-app>
    <v-main>

      <!-- pas connecté -->
      <div v-if="!$manager.equipe.uid"
           class="login-screen" >
        <!-- loading-->
        <v-progress-circular
            v-if="$manager.listeEquipes.length===0"
            indeterminate color="var(--color-bleu-bca"/>
        <!-- login box-->
        <v-card
            v-else
            elevation="10" width="400">
          <v-card-text class="pa-10">
            <div class="message mb-5">
              <b>Bonjour</b><br>
              Veuillez vous identifier pour participer aux Olympiades.
            </div>
            <login-equipe/>
          </v-card-text>
        </v-card>

      </div>


      <!-- le jeu-->
      <game-component v-if="$manager.equipe.uid"/>
      <!-- logos-->
      <img src="logo/Logo-Olympiade-2021.svg"
           width="120px" class="img-logo"
           style="
           top:20px;
           left: 20px;"
           alt="logo"
      />
      <img src="logo/logo-bca.png"
           width="60px" class="img-logo"
           style="
           bottom:20px;
           left: 20px;"
           alt="logo"
      />

      <!-- preload assets-->
      <div style="visibility: hidden;position: absolute;pointer-events: none;">
        <img src="choix-perso/homme.png" alt="">
        <img src="choix-perso/femme.png" alt="">
        <img src="choix-perso/fond.jpg" alt="">
      </div>


      <!--footer-->
      <v-footer fixed color="transparent" class="caption justify-end" style="pointer-events: none;">
        {{$manager.version}}
        <span class="mx-5">{{$manager.projectDesc}} </span>
        <v-btn
            v-if="$manager.debug.debugQuestions"
            style="pointer-events: auto"
            class="ml-2" x-small
            @click="$manager.debugDrawerOpen=true">
          débug
        </v-btn>
      </v-footer>
      <!--debug-->
      <debug-drawer v-if="$manager.debug.debugQuestions"/>
      <!--scores-->
      <transition name="fade-scale" mode="out-in">
        <tableau-scores v-if="$manager.displayModal==='scores'"/>
      </transition>
      <!--règles-->
      <transition name="fade-scale" mode="out-in">
        <regles v-if="$manager.displayModal==='regles'"/>
      </transition>
      <!--menu-->
      <nav-game v-if="$manager.equipe.uid"/>

    </v-main>
  </v-app>
</template>

<script>

import GameComponent from "@/game/game-component";
import LoginEquipe from "@/ui/login-equipe";
import NavGame from "@/ui/nav-game";
import TableauScores from "@/ui/tableau-scores";
import DebugDrawer from "@/game/debug-drawer";
import Regles from "@/ui/regles";
export default {
  name: 'App',
  components: {
    Regles,
    DebugDrawer,
    TableauScores,
    NavGame,
    LoginEquipe,
    GameComponent
  },

  data: () => ({
    //
  }),
  methods:{
  }
};
</script>

<style lang="less">
@import "./ui/all";
@import "~animate.css/animate.min.css";

//transitions vuejs
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-scale-enter-active, .fade-scale-leave-active {
  transition: opacity 0.5s;
}
.fade-scale-enter, .fade-scale-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-up-enter-active, .fade-up-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}
.fade-up-enter, .fade-up-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}

html{
  overflow-y: auto;
}
h1{
  color: mediumvioletred;
}
.login-screen{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .message{
    font-family: var(--font-family-normal);
    color: var(--color-blue-dark);
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
  }
  fieldset{
    //border-color: ;
  }
  .theme--light.v-select .v-select__selections{
    color:var(--color-blue-dark);
  }
  .theme--light.v-input input, .theme--light.v-input textarea{
    color:var(--color-blue-dark);
  }
}
.img-logo{
  position: fixed;
  user-select: none;
  z-index: 1;
  pointer-events: none;
}
.nav-game{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}


//z
.modal-game{
  z-index: 10;
}



</style>
