import Phaser from "phaser";
import Player from "@/game/Objets/Player";
import SectionDisplay from "@/game/Objets/SectionDisplay";
import BaseScene from "@/game/scenes/BaseScene";
/**
 * Toutes les fonctions propres à un tableau dans notre jeu.
 * Cette classe n'est pas à utiliser directement, elle doit être extend !
 */
export default class TableauPhaser extends BaseScene
{

    constructor(key)
    {
        super(key);



        //console.log("build tableau phaser",key,this.rand)
        this.isTableau=true;

        this.music="audio-loop-tableau";

        this._skyX=0;
        /**
         * Définit si on est en mode loading ou non
         * @type {boolean}
         */
        this.readyToPlay=false;
        /**
         *
         * @type {Player}
         */
        this.player=null;
        /**
         * Identifiants de tous les pnj
         * @type {string[]}
         */
        this.pnjIdentifiers=[
            "femme-blonde",
            "femme-rousse",
            "homme-barbu-brun",
            "homme-brun",
            "homme-voleur",
            "policier-black",
            "policier",
            "pompier",
            "agriculteur",
            "agricultrice",
            "garagiste-bleu",
            "policier-femme",
            "ouvrier-bouc",
            "pompier-femme",
            "homme-cravate",
            "garagiste-orange",
            "ouvrier-barbe",
            "operatrice",
            "homme-cravate-bouc",
            "giletjaune-bouc",
            "giletjaune-barbe",
            "femme-cravate"

        ];
        /**
         * Identifiants de tous les triggersIdentifiers
         * @type {string[]}
         */
        this.triggersIdentifiers=[
            "trigger-enveloppe",
            "trigger-question",
            "trigger-block",
            "trigger-tablette",
            "valider"
        ];
        /**
         * identifiants de tous les tilesetimages
         * @type {string[]}
         */
        this.tileImagesIdentifiers=[
            "objets",
            "vehicules",
            "p3-batiments",
            "p4-batiments",
            "horizon",
            "palette",
            "sol",
        ];

        /**
         * Les tileset images qui seront ensuite utilisés dans les layers de la carte
         * @type {Phaser.Tilemaps.Tileset[]}
         */
        //this.tileImages=[];

        /**
         * La map de ce tableau
         * @type {Phaser.Tilemaps.Tilemap}
         */
        //this.map=null;

    }

    preload(){

        // eslint-disable-next-line no-unused-vars
        let t=Date.now();
        //console.log("preload start")
        super.preload();
        //loading
        let me=this;
        me.$manager.currentTableauProps.loadingPercent=0;
        this.load.on('progress', function (value) {
            me.$manager.currentTableauProps.loadingPercent=value*90;
        });

        this.load.audio(this.music,'music/EnjoyExperience-comp.mp3')

        //chargement dynamiques
        for(let perso of this.pnjIdentifiers){
            this.load.image(perso, 'tile-assets/'+perso+'.png');
        }
        for(let trigger of this.triggersIdentifiers){
            this.load.image(trigger, 'tile-assets/'+trigger+'.png');
        }
        for(let tileImagesIdentifier of this.tileImagesIdentifiers){
            this.load.image(tileImagesIdentifier, 'tile-assets/'+tileImagesIdentifier+'.png');
        }

        //divers
        this.load.image('block', 'tile-assets/block.png');
        this.load.image('particule', 'game/particule.png');
        this.load.image('sky', 'game/sky.jpg');
        this.load.image('nuages','game/nuages.png');

        //spritesheet du player homme ou femme
        this.load.atlas('player', `game/player/${window.$manager.personnage}.png`, `game/player/${window.$manager.personnage}.json`);
        //console.log("preload end",(Date.now()-t)/1000);
    }

    create(mapKey){
        super.create();

        //console.log("----> create start",mapKey)
        let me=this;
        let t=Date.now();
        me.readyToPlay=false;




        /**
         * Là où sont posé tous les trigger pnj etc
         * @type {Phaser.GameObjects.Container}
         */
        this.plan2Objects=this.add.container().setVisible(false);




        //pour opitDeplacement
        this.arrowRightUnpressed = false;
        this.arrowLeftUnpressed = false;
        this.arrowRightPressed = false;
        this.arrowLeftPressed = false;

        /**
         * Objet joueur
         * @type {Player}
         */
        me.player = new Player(this, 100, 800, 'player');
        me.player.setVisible(false);

        setTimeout(function(){
            me.buildMap(mapKey);
            //console.log("buildMap",Date.now()-t)
            me.$manager.currentTableauProps.loadingPercent=91;
            me.initCiel("sky","nuages");
            setTimeout(function(){
                me.initDecor(function(){
                    //console.log("initDecor done",Date.now()-t)
                    me.placePlayer();
                    me.initProfondeur();
                    me.readyToPlay=true;
                    console.log("----> create end",(Date.now()-t)/1000,mapKey)
                });
            },100)
        },100);
    }

    update(time, delta){
        if(!this.readyToPlay){
            return;
        }
        super.update(time, delta);
        if(!window.$manager.playerCanMove) {
            this.player.stop();
        }
        this.player.move(time, delta);
        this.optiDeplacement();
        this._skyX+= 0.2;
        this.nuages.tilePositionX= this._skyX +   this.cameras.main.scrollX * 0.4;
        //console.log(this.player.y);
        if(this.player.y>1200){
            this.playerTombe();
        }
    }

    playerTombe(){
        if(this.tombe){
            return;
        }
        this.tombe=true;
        console.log("tombe")
        this.cameras.main.shake(200,0.01)
        this.soundTombe();
        for(let s of this.sectionsData){
            if(s.displayObject
                && s.displayObject.x < this.player.x
                && s.displayObject.x+s.displayObject.displayWidth > this.player.x){
                this.player.setPosition(s.displayObject.x - 30,0);
                this.tombe=false;
                return ;
            }else{
                if(s.displayObject){
                    console.log("NOK ",
                        s.name,
                        s.displayObject.x < this.player.x,
                        s.displayObject.x + s.displayObject.displayWidth > this.player.x,
                        s.displayObject.x,
                        s.displayObject.displayWidth,
                        this.player.x,
                    );
                }

            }
        }
        /*
        if(window.$manager.currentSection){
            window.$manager.currentSection.placePlayer(false);
        }else{
            console.error("pas de currentSection")
        }

         */
    }


    /**
     *
     * @return {TableauModel}
     */
    get tableauModel(){
        return window.$manager.getTableauModel(this.key);
    }

    /**
     *
     * @return {Section[]}
     */
    get sectionsData(){
        return this.tableauModel.sectionsData;
    }





    /**
     * Débloque les touches
     */
    optiDeplacement(){
        if(this.player.staticY){ // Quand le joueur ne saute pas ni ne tombe
            if(this.arrowLeftUnpressed){
                if(this.arrowRightPressed){
                    this.player.directionX = 1;
                    this.arrowLeftUnpressed = false;
                }else{
                    this.player.directionX = 0;
                    this.arrowLeftUnpressed = false;
                }
            }
            else if(this.arrowRightUnpressed){

                if (this.arrowLeftPressed){
                    this.player.directionX = -1;
                    this.arrowRightUnpressed = false;
                }else{
                    this.player.directionX = 0;
                    this.arrowRightUnpressed = false;
                }

            }
            if (!this.arrowLeftPressed && !this.arrowRightPressed){
                this.player.directionX = 0;
            }
        }

    }



    /**
     * initialise la map
     * Associe les tileset image
     * Configure la caméra
     * @param jsonKey
     */
    buildMap(jsonKey){
        // eslint-disable-next-line no-unused-vars
        let t=Date.now();
        /**
         * La map de ce tableau
         * @type {Phaser.Tilemaps.Tilemap}
         */
        this.map = this.make.tilemap({key: jsonKey});
        //console.log("build Map",(Date.now()-t)/1000);
        //charge les tilesetImage
        t=Date.now();
        this.tileImages=[];
        for(let tileImagesIdentifier of this.tileImagesIdentifiers){
            this.tileImages.push(this.map.addTilesetImage(tileImagesIdentifier, tileImagesIdentifier));
        }
        //console.log("tileImages.push",(Date.now()-t)/1000);
        this.cameras.main.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);
        this.physics.world.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels*2);
        //this.cameras.main.setRoundPixels(true);
        this.cameraFollow(true);

        let me=this;
        this.initSections();
        this.map.filterObjects(this.map.getObjectLayer("p2-objects"),function(obj){
            let section=me.getSectionOfObject(obj);
            if(section){
                section.displayObject.addObject(obj,obj.properties);
            }
        })
    }

    /**
     * Positionne le player
     * @private
     */
    placePlayer(){
        /**
         *Point de Tiled servant de point de spawn pour le joueur
         * @type {Phaser.Types.Tilemaps.TiledObject}
         */
        let spawnPoint = this.map.findObject("spawn", obj => obj.name === "spawn");
        if(spawnPoint){
            this.player.setPosition(spawnPoint.x, spawnPoint.y);
        }
        //console.log("emit player-landing")
        this.events.emit("player-landing");
    }

    initSections(){
        let me=this;
        let sectionsMapObjects = this.map.getObjectLayer("sections")['objects'];
        this.sections=this.add.container(0,0).setVisible(false);
        sectionsMapObjects.forEach(section =>{
            let sectionData=me.tableauModel.getSectionModelByName(section.name);
            if(sectionData){
                let sectionDisplay=new SectionDisplay(me,section,sectionData)
                me.sections.add(sectionDisplay);
                /*
                if(!sectionData.complete){

                }else{
                    //console.log("section "+sectionData.name+" est terminée")
                }

                 */
            }else{
                console.warn(`La section définie dans tiled comme ${section.name} n'existe pas dans les datas de l'API pour le tableau ${me.key}`)
            }

        });
    }

    /**
     * Active ou désactive le suivi de caméra
     * @param {boolean} follow Suivre ou ne pas suivre le joueur
     * @param {boolean} direct Si false fera une transition
     */
    cameraFollow(follow,direct=true){
        let me=this;

        if(follow){
            if(direct){
                me.cameras.main.startFollow(me.player, true,0.1,1,-560,64);
                //me.cameras.main.setFollowOffset();
            }else{
                window.$manager.cameraIsMoving=true;
                //this.cameras.main.setFollowOffset(-560,64);
                this.cameras.main.pan(
                    this.player.x+560,
                    this.player.y-64,
                    2000,
                    Phaser.Math.Easing.Sine.InOut,
                    true,
                    function(camera,progress){
                        if(progress===1){
                            me.cameraFollow(true,true);
                            window.$manager.cameraIsMoving=false;
                        }
                    }
                )
            }

        }else{
            this.cameras.main.stopFollow();
        }
    }

    /**
     * Renvoie la section d'un display object Phaser
     * @param object
     * @return {Section}
     */
    getSectionOfObject(object=null){
        let ret=null;
        this.sections.iterate(function(section){
                if(Phaser.Geom.Rectangle.Contains(section.getBounds(),object.x,object.y)){
                    //console.log("getSectionOfObject",section.section.name,object.name)
                    ret = section.section;
                }
            }
        );
        return ret;
    }

    initCiel(sky,nuages){
        //le ciel
        /**
         *Le ciel bleu
         * @type {Phaser.GameObjects.Sprite}
         */
        this.sky = this.add.sprite(0, 0, sky).setVisible(false);
        this.sky.setOrigin(0, 0);
        this.sky.setScrollFactor(0, 0);
        this.nuages=this.add.tileSprite(
            0,
            0,
            this.sys.canvas.width,
            this.sys.canvas.height,
            nuages
        );
        this.nuages.setOrigin(0,0).setVisible(false);
        this.nuages.setScrollFactor(0, 0);
    }
    /**
     * Construit un container de tiles
     * @private
     * @param {String[]} layerIds Liste des ids des layers organisés du plus bas au plus haut
     * @param {number} scrollFactor facteur de scroll parallaxe
     * @param complete
     */
    buildMapContainerAsync(layerIds,scrollFactor=1,complete=function(){}){

        //crée le container
        layerIds=[...layerIds];
        let container=this.add.container(0,0).setVisible(false);

        container.scrollFactorX=scrollFactor;

        let me=this;
        let build=function(cb){
            setTimeout(function(){
                if(layerIds.length>0){
                    let layerId=layerIds.shift();
                    let layer=me.map.createLayer(layerId, me.tileImages, 0, 0);
                    container.add(layer);
                    build(cb);
                }else{
                    cb();
                }
            },10)
        }
        build(function(){
            complete(container);
            /*
            me.optimizeContainer(container,function(){
                complete(container);
            });
            */
        });

        return container;
    }

    /**
     * Permet d'applatir tous les bitmaps d'un container en un seul bitmap
     * @private
     * @param {Phaser.GameObjects.Container} container
     */
    optimizeContainer(container,cb){
        // eslint-disable-next-line no-unused-vars
        let t=Date.now();
        let total=20;
        /**
         * Enregistre les layers (pour pouvoir les manipuler puis les effacer définitivement)
         * @type {*[]}
         */
        let layers=[];
        container.iterate(function(layer){
            layers.push(layer);
        })
        let bmps=[];
        let slice=this.map.widthInPixels/total;
        let me=this;
        let createBmp=function(cb){
            setTimeout(function(){
                let bmp=me.add.renderTexture(0,0,slice,me.map.heightInPixels);
                bmps.push(bmp);
                if(bmps.length===total){
                    cb()
                }else{
                    createBmp(cb);
                }
            },1)

        }
        createBmp(function(){
            /**
             * Dessine dans le container
             */
            for(let i=0;i<total;i++){
                // eslint-disable-next-line no-unused-vars
                let tt=Date.now();
                //décale les objets pour le 2e bmp
                container.iterate(function(layer){
                    layer.x=slice*i*-1;
                })
                bmps[i].draw(container)
            }

            //nettoiel les layers qui sont maintenant inutiles
            container.removeAll(true);
            for(let layer of layers){
                layer.destroy(true,true);
                layer=null;
            }
            for(let i=0;i<total;i++){
                container.add(bmps[i]);
                bmps[i].x=slice*i;
                bmps[i].y=Phaser.Math.Between(0,100);
            }
            //console.log("optiContainer end",Date.now()-t);
            cb();
        })



    }

    /**
     * initialise les différents calques tirés de Tiled
     */
    initDecor(complete=function(){})
    {
        let me=this;
        //let t=Date.now();
        let containers=[
            {
                container:"plan1Container",
                layerIds:['p1'],
                scroll:1.2
            },
            {
                container:"plan2Container",
                layerIds:[
                    'p2-sol',
                    'p2-objets',
                ],
                scroll:1
            },
            {
                container:"plan3Container",
                layerIds:[
                    'p3-sol',
                    'p3-maisons',
                    'p3-fenetres',
                    'p3-devantures',
                    'p3-objets'
                ],
                scroll:0.95
            },
            {
                container:"plan4Container",
                layerIds:[
                    'p4-sol',
                    'p4-objets',
                ],
                scroll:0.75
            },
            {
                container:"horizonContainer",
                layerIds:['horizon'],
                scroll:0.6
            },

        ]

        let next=function(allBulided){
            setTimeout(function(){
                let c = containers.shift();
                if(c){
                    me[c.container]=me.buildMapContainerAsync(c.layerIds,c.scroll,function(){
                        me.$manager.currentTableauProps.loadingPercent+=2;
                        next(allBulided);
                    });
                }else{
                    allBulided();
                }
            },10);
        }
        next(function(){
            /**
             * Le sol
             * @type {Phaser.Tilemaps.TilemapLayer}
             */
            me.sol = me.map.createLayer('sol', me.tileImages, 0, 0).setVisible(false);

            /**
             * Le plan qui permet de déterminier les collisions
             * @type {Phaser.Tilemaps.TilemapLayer}
             */
            me.collide = me.map.createLayer('collide', me.tileImages, 0, 0);
            me.collide.setCollisionByExclusion(-1, true);
            me.physics.add.collider(me.player, me.collide);
            complete();
        })
    }

    /**
     * Initialise la profondeur pour chaque calque ou container d'objets
     */
    initProfondeur()
    {
        //init profondeur
        let z = 1000; //niveau Z qui a chaque fois est décrémenté.

        // devant
        //this.sky.setDepth(z--);
        this.collide.setDepth(z--);
        this.collide.visible=window.$manager.debug.arcadePhysics;

        this.sections.setDepth(z--);
        this.sections.visible=window.$manager.debug.arcadePhysics;

        this.plan1Container.setDepth(z--).setVisible(true);
        this.player.setDepth(z--).setVisible(true);
        this.plan2Objects.setDepth(z--).setVisible(true);
        this.plan2Container.setDepth(z--).setVisible(true);
        this.plan3Container.setDepth(z--).setVisible(true);
        this.plan4Container.setDepth(z--).setVisible(true);
        this.sol.setDepth(z--).setVisible(true);
        this.horizonContainer.setDepth(z--).setVisible(true);

        //derrière

        this.sky.setVisible(true);
        this.nuages.setVisible(true);
    }

}
