import Phaser from "phaser";
import SceneMap from "@/game/scenes/SceneMap";
import DemiFinaleCommunes1 from "@/game/scenes/DemiFinaleCommunes1";
import Fin from "@/game/scenes/Fin";

/**
 * 
 * @type {BaseScene[]}
 */
window.phaserScenes=[
    new SceneMap("Map"),
    new DemiFinaleCommunes1("DemiFinaleCommunes1"),
    new Fin("fin")
]

export default class BcaTown
{

    constructor()
    {
        //Declaration des varaibles pour les dimensions de la fenêtre de jeu
        let width=28*32; //896;
        let height=14*32; //448;
        width=44*32;//1408
        height=22*32;//704

        this.gameConfig =
            {
            width: width,
            height: height,
            backgroundColor:0x87D9E4,
                fps:{
                    target:30
                },
            type: Phaser.AUTO,
                physics:
                    {
                        default: 'arcade',
                        arcade:
                            {
                                gravity: { y: 900 },
                                debug: window.$manager.debug.arcadePhysics,
                            }
                    },
            scale: {
                parent: "bca-town",
                width: width,
                height: height,
                min:
                    {
                        width: width*0.8,
                        height: height*0.8
                    },
                max:
                    {
                        width: width*1,
                        height: height*1
                    },
                autoCenter: Phaser.Scale.CENTER_BOTH,
                orientation: Phaser.Scale.LANDSCAPE,
                mode: Phaser.Scale.FIT
            },
            scene: window.phaserScenes
        };
        /**
         *
         * @type {Phaser.Game}
         */
        window.game = new Phaser.Game(this.gameConfig);
        window.game.scale.on("resize",function(){
            window.$manager.emit(window.$manager.EVENT_GAME_RESIZE);
        })


    }

}