<template>
<div class="nav-game">
  <v-btn text x-small tile
         @click="$manager.displayModal='scores';">
    classement
  </v-btn>
  <v-btn text x-small tile
         @click="$manager.displayModal='regles'">
    règles du jeu
  </v-btn>
  <v-btn text x-small tile
  @click="$manager.logOut()"
  >
    déconnexion
  </v-btn>
</div>
</template>

<script>
export default {
  name: "nav-game"
}
</script>

<style lang="less">
.nav-game{
  display: flex;
  justify-content: flex-end;
  >*{
    margin: 5px;
    opacity: 0.5;
  }
}
</style>