export default class Equipe {
    /**
     * La classe PlayerModel (à ne pas confondre avec Player qui est utilisé par phaser)
     * permet de stocker des information sur le joueur telles que son nom, sa progression dans le jeu, son nombre de points etc...
     */
    constructor(json=null) {
        this.uid="";
        /**
         * Points gagnés par le joueur
         * @type {number}
         * @readonly
         */
        this.points=0;
        /**
         *
         * @type {number}
         * @readonly
         */
        this.totalReponses=0;
        /**
         *
         * @type {number}
         */
        this.totalReponsesSession=0;
        /**
         *
         * @type {number}
         * @readonly
         */
        this.totalQuestions=0;
        /**
         *
         * @type {number}
         */
        this.totalQuestionsSession=0;
        /**
         *
         * @type {string}
         * @readonly
         */
        this.name="???";
        /**
         *
         * @type {string}
         * @readonly
         */
        this.unite="???";
        this.geo="???";
        this.numero="???";

        /**
         * Position dans le classement
         * @type {number}
         */
        this.position=999;
        /**
         * Temps de réponse moyen à chaque question juste
         * @type {number}
         */
        this.tempsReponse=25;
        /**
         * Taux de réponses justes
         * @type {number}
         */
        this.tauxReponse=86;

        /**
         * Identifiant qui permet de s'assurer qu'on ne s'est pas loggé autre part entre temps
         * @type {null}
         */
        this.loginid=null;

        if(json) {
            this.setJson(json)
        }
    }

    /**
     *
     * @returns {string}
     */
    get pointsFormated(){
        return new Intl.NumberFormat().format(this.points);
    }

    /**
     * Pour définir les valeurs (appelé depuis l'API)
     * @param json
     */
    setJson(json){
        this.uid=json.uid;
        this.name=json.name;
        this.geo=json.geo;
        this.numero=json.numero;
        this.unite=json.unite;
        this.totalReponses=json.totalReponses;
        this.totalQuestions=json.totalQuestions;
        this.totalReponsesSession=json.totalReponsesSession;
        this.totalQuestionsSession=json.totalQuestionsSession;
        this.points=json.points;
        this.position=json.position;
        this.tauxReponse=json.tauxReponse;
        this.tempsReponse=json.tempsReponse;
        this.loginid=json.loginid;
    }

    /**
     * Pourcentage de progression dans le jeu
     * @returns {number}
     */
    get questionProgressPercent(){
        return Math.round(100/this.totalQuestionsSession * this.totalReponsesSession)
    }
}