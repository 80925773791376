import Question from "@/model/Question";
import BaseModel from "@/model/BaseModel";

/**
 * Le modèle de gestion d'une section
 * @property {SectionDisplay} displayObject - L'objet phaser de cette section (non déclaré pour ne pas etre watché)
 */
export default class Section extends BaseModel
{
    /**
     *
     * @param data
     * @param {TableauModel} tableau
     * @property {SectionDisplay} displayObject - L'objet phaser de cette section
     */
    constructor(data,tableau)
    {
        super();
        // eslint-disable-next-line no-unused-vars
        let me=this;
        /**
         * @type {TableauModel}
         */
        this.tableau=tableau;
        /**
         * Identifiant texte de la section
         */
        this.name=data.name;
        /**
         * La section est elle active?
         * @type {boolean}
         * @private
         */
        this._active=false;
        /**
         * Le mode d'affichage des questions
         * @type {string}
         */
        this.display=data.display;
        /**
         * Est ce que la section est terminée ou non?
         * @type {boolean}
         */
        this.complete=false;
        /**
         *
         * @type {Question[]}
         */
        this.questions=[];

        for(let q of data.questions)
        {
            let question=new Question(q,this);
            question.display=this.display;
            if(this.display===Section.DISPLAY_BLOCKS){
                question.indication="(Sautez sur la bonne réponse)";
            }
            if(question.qcm){
                question.indication="(plusieurs réponses possibles)";
            }
            this.questions.push(question)
            question.on("status_change",function(){
                if(question.isPassed && me.active){
                    me.nextStep();
                }
            })
        }



        /**
         * si on a répondu à toutes les questions de la section
         * @type {boolean}
         */
        this.complete=true;
        //teste si la section est finie
        for(let q of this.questions){
            if(q.status!==Question.STATUS_PASSED){
                this.complete=false;
            }
        }

    }



    /**
     * Est ce que le joueur est dans la section
     * @return {boolean}
     */
    get isPlayerIn(){
        return this.displayObject && this.displayObject.isPlayerIn;
    }
    set active(value){
        if(value===this._active){
            return;
        }
        this._active=value;
        if(this._active){
            window.$manager.updateTitle();
            //pour les blocks...
            if(this.display===Section.DISPLAY_BLOCKS){
                this.tableau.tableauPhaser.cameraFollow(false);
                for(let r of this.displayObject.blocksReponses){
                    r.play();
                }
            }
            //afficher la bonne question
            for(let q of this.questions){
                switch (q.status){
                    case Question.STATUS_ACTIVABLE:
                    case Question.STATUS_INACTIVE:
                        q.status=Question.STATUS_ACTIVE;
                        return;
                }
            }

            //est ce que toutes les questions sont terminées ?
            let complete=true;
            for(let q of this.questions){
                if(q.status !== Question.STATUS_PASSED){
                    complete = false;
                }
            }
            this.complete=complete;
        }
    }
    get active() {
        return this._active && this.isPlayerIn && !this.complete;
    }
    /**
     * Déplace le joueur au début ou à la fin de la section
     */
    placePlayer(endOfSection=false){
        let me=this;
        let tableauPhaser=this.tableau.tableauPhaser;
        //console.log("LISTEN placePlayer",tableauPhaser.key,tableauPhaser.rand,tableauPhaser)
        tableauPhaser.events.once("player-landing",function(){
            //console.log("player-landing");
            if(me.displayObject){
                if(endOfSection){
                    tableauPhaser.player.setPosition(me.displayObject.x+me.displayObject.width,0);
                }else{
                    tableauPhaser.player.setPosition(me.displayObject.x,0);
                }
            }
        })

    }


    nextStep(){
        let theNextOne=false;
        if(this.active){
            for(let q of this.questions){
                if(q.isInactive){
                    q.status=Question.STATUS_ACTIVABLE;
                    if(theNextOne){
                        //passage automatique à la question suivante dans cette même section
                        q.status=Question.STATUS_ACTIVE;
                    }
                    return;
                }
                if(q.isActivable){
                    q.status=Question.STATUS_ACTIVE;
                    return;
                }
                if(q.isPassed){
                    theNextOne=true;
                }
            }
        }
        if(this.questions[this.questions.length-1].isPassed){
            this.complete=true;
            this.emit("section-complete");
        }

        //console.log("rien à faire")
    }



}

Section.DISPLAY_BULLES_CHECK="DISPLAY_BULLES_CHECK";
Section.DISPLAY_BLOCKS="DISPLAY_BLOCKS";
Section.DISPLAY_LETTRE="DISPLAY_LETTRE";
Section.DISPLAY_TABLETTE="DISPLAY_TABLETTE";
Section.DISPLAY_PHONE="DISPLAY_PHONE";

Section.DISPLAY_ALL=[
  Section.DISPLAY_BULLES_CHECK,
  Section.DISPLAY_BLOCKS,
  Section.DISPLAY_LETTRE,
  Section.DISPLAY_TABLETTE,
  Section.DISPLAY_PHONE
];
