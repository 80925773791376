<template>
<div class="bulles-check">
    <bulle-question
        class="animate__fadeIn animate__animated"
        :question="question"
        :time="chronoString"
        :texte="texteBulleQuestion"
        :indication="indicationBulleQuestion"
    />
  <bulle-reponses
      class="animate__fadeIn animate__animated animate__delay-1s"
      v-if="displayBulleReponses"
    :question="question"
  />
</div>
</template>

<script>
import BulleQuestion from "@/game/questions/components/bulle-question";
import QuestionMixin from "@/game/questions/QuestionMixin";
import BulleReponses from "@/game/questions/components/bulle-reponses";
export default {
  name: "bulles-check",
  components: {BulleReponses, BulleQuestion},
  mixins:[QuestionMixin],
  computed:{

  }
}
</script>

<style lang="less">
.bulles-check{
  //border: 1px solid blue;
  .bulle-question{
    position: absolute;
    max-width: 70%;
    right: calc( var(--scale-factor) * 120px );
    top: calc( var(--scale-factor) * 50px )
  }
  .bulle-reponses{
    //outline: 1px solid red;
    position: absolute;
    max-width:  calc( var(--scale-factor) * 802px );
    min-width:  calc( var(--scale-factor) * 300px );
    left: calc( var(--scale-factor) * 200px );
    top: calc( var(--scale-factor) * 200px )
  }
}
</style>