<template>
<div class="anim-textes">
  <div class="slides">
  <slot/>
  </div>
</div>
</template>

<script>
import {gsap} from "gsap"

export default {
  name: "anim-textes",
  data: function () {
    return {
      tl: null
    }
  },
  props:{
    pauseEnd: {
      type:Boolean,
      default:false
    }
  },
  computed: {
    showProgression() {
      return this.$manager.equipe.totalReponses > 3;
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let me = this;
    let tl = this.tl = gsap.timeline(
        {
          repeat: 0,
          paused: false,
          onComplete: function () {
            console.log("anim complete")
            me.$emit("ended");
          }
        }
    );
    let $slides = this.$el.querySelectorAll(".slide");
    let sens = -1;
    gsap.set($slides, {display: 'none'});
    tl.set($slides,{},"+=1.0")
    $slides.forEach(function ($s) {

      let $phrases = $s.querySelectorAll(".phrase");
      tl.set($s, {display: 'flex'})
      $phrases.forEach(function ($ph) {
        sens = sens * -1;
        tl.fromTo($ph,
            {
              x: -1000 * sens,
              opacity: 0
            }, {
              opacity: 1,
              duration: 0.25, x: 0
            }
        );
      })

      //pause sur la phrase
      if (!$s.matches(":last-child")) {
        tl.set($s, {}, "+=3s");
      }else{
        tl.set($s, {}, "+=1s");
      }


      if (!me.pauseEnd || !$s.matches(":last-child")) {
        //fait disparaitre les phrases sauf si le jeu est fini et que c'est le dernier slide
        $phrases.forEach(function ($ph) {
          sens = sens * -1;
          tl.to($ph, {
                duration: 0.25,
                opacity: 0,
                y: -300 * sens,
              },
              "-=0.1s")
          tl.set($s, {display: 'none'})
        });
        /*
        if ($s.matches(":last-child")) {
          tl.set($s, {}, "+=1s");
        }
         */
      }


    })
  }
}
</script>

<style lang="less">
.anim-textes{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .slides{
    height: 100%;
    width: 100%;
    position: absolute;
    .slide{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transform: skewY(-7deg);
    }
  }
  .phrase{
    text-align: center;
    font-weight: 900;
    font-family: var(--font-family-normal);
    color: #fff;
    font-size: calc( 80px * var(--scale-factor));
    text-transform: uppercase;
    small{
      font-size: 50%;
      text-transform: none;
    }
    .y{
      display: inline-block;
      position: relative;
      margin-left: 0.3em;
      margin-right: 0.3em;
      &:before{
        transform: translateX(-0.3em) translateY(0.3em);
        position: absolute;
        display: block;
        content: '';
        z-index: -1;
        line-height: 1em;
        background-color: var(--color-yellow);
        width: 100%;
        height: 70%;
        animation-name: yellow;
        animation-iteration-count: infinite;
        animation-duration: 2s;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;
        @keyframes yellow{
          from{
            transform: translateX(-0.3em) translateY(0.3em);
          }
          to{
            transform: translateX(-0.2em) translateY(0.1em) scale(1.05);
          }
        }
      }
    }
  }

  .buttons{
    position: absolute;
    bottom: 20px;
    right: 20px;
    >*{
      pointer-events: auto;
      margin: 20px;
    }
  }

}
</style>