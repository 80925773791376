<template>
<div class="question-lettre animate__fadeInUp animate__animated"
  :class="{
    'is-tablette':question.isDisplayTablette,
    'is-lettre':question.isDisplayLettre
  }"
>
  <img class="bg"
       alt=""
       v-if="question.isDisplayLettre"
       src="./components/lettre.svg"
  />
  <img class="bg"
       alt=""
       v-if="question.isDisplayTablette"
       src="./components/ipad.png"
  />
  <div class="content">
    <div>

      <timer-question
          :question="question"/>
        <reponses
            v-if="displayBulleReponses"
            :question="question"/>
    </div>
    <btn-check
        v-if="displayBulleReponses"
        :active="question.isPlayerReponsePossible"
        @click.native="question.sendReponse()"
    />
  </div>
</div>
</template>

<script>
import QuestionMixin from "@/game/questions/QuestionMixin";
import BtnCheck from "@/ui/btn-check";
import Reponses from "@/game/questions/components/reponses";
import TimerQuestion from "@/game/questions/components/timer-question";

export default {
  name: "question-lettre",
  components: {TimerQuestion, Reponses, BtnCheck },
  mixins:[QuestionMixin],
  computed:{

  }
}
</script>

<style lang="less">
.question-lettre{
  height: 600px;
  width: 700px;
  //outline: 1px solid green;
  right: 0;
  top: -60px;
  position: absolute;
  .bg{
    //outline: 1px solid red;
    position: absolute;
    width: 100%;
  }
  .content{
    //background-color: rgba(255, 0, 255, 1.79);
    position: absolute;
    //outline: 1px solid grey;
    outline-offset: -2px;
    top: 100px;
    width: 70%;
    left: 15%;
    height: 500px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .reponses{
      //margin-top: 30px;
      padding: 30px;
      padding-left: 15px;
    }

    .btn-check{
      margin-top: auto;
      margin-bottom: 0;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  .btn-continuer{
    margin-top: 15px;
  }

  &.is-tablette{
    width: 600px;
    right: 50px;
    top: -70px;
    .content{
      //outline: 1px solid red;
      top: 100px;
      height: 550px;
    }
  }

  .timer-question{

    flex-direction: column;
    justify-content: center;
    align-items: center;
    .chrono{
      margin: 0;
      width: calc(97px * var(--scale-factor));
      height: calc(97px * var(--scale-factor));
      font-size: calc(16px * var(--scale-factor));
    }
    .question{
      text-align: center;
    }
  }


}
</style>