export default class BullePNJ{
    constructor(pnjId) {
        this.x=0;
        this.y=0;
        this.active=true;
        this.pnjId=pnjId;
    }

    get visible(){
        return this.x > 150
            && this.x<1300
            && !window.$manager.displayLoadingText
            && !window.$manager.isLoading
            && !window.$manager.currentQuestion
            ;
    }
}