<template>
<div class="question-type-switcher">
  <bulles-check
      v-if="question.isDisplayBullesCheck"
      :question="question"/>
  <question-blocks
      v-if="question.isDisplayBlocks"
      :question="question"/>
  <question-lettre
      v-if="question.isDisplayLettre || question.isDisplayTablette"
      :question="question"/>
</div>
</template>

<script>
import QuestionMixin from "@/game/questions/QuestionMixin";
import BullesCheck from "@/game/questions/bulles-check";
import QuestionBlocks from "@/game/questions/question-blocks";
import QuestionLettre from "@/game/questions/question-lettre";
export default {
  name: "question-type-switcher",
  mixins:[QuestionMixin],
  components: {QuestionLettre, QuestionBlocks, BullesCheck}
}
</script>

<style lang="less">
.question-type-switcher{

}
</style>