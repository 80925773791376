import BaseObject from "@/game/Objets/BaseObject";
import * as Phaser from "phaser";
export default class Trigger extends BaseObject
{
    constructor(props,tableau)
    {
        super(props,tableau);
        this.tween=null;
        /**
         * Les propriétés récupérées depuis tiled
         * @type  {Phaser.Types.Tilemaps.TiledObject}
         */
        this.props = props;
        /**
         *
         * @type {Tableau}
         */
        this.tableau = tableau;

        /**
         * Si c'est un objet de validation
         * @type {boolean}
         */
        this.isValidate = props.name==="valider";
        this.buildDisplay();

    }

    buildDisplay() {
        let me=this;
        if(!this.builded){
            this.particles = this.tableau.add.particles('particule');
            this.group.add(this.particles);
            this.particles.setPosition(52,-32)
            this.emmiter = this.particles.createEmitter({
                frequency: 100,
                lifespan: Phaser.Math.Between(1500,2500),
                quantity: 5,
                x: {min: -32, max: 32},
                y: {min: -32, max: 32},
                tint: [  0xB85901, 0x753901, 0xF57802, 0x361A01, 0xDB6B02 ],
                rotate: { min:0, max:360 },
                radial: true,
                scale: { start: 2, end: 0.1 },
                alpha: { start: 1, end: 0 },
                blendMode: Phaser.BlendModes.ADD,
                speed: 50
            });
            this.emmiter.stop();
            super.buildDisplay();
            this.displayObject.setOrigin(0,1)
            this.tableau.physics.add.overlap(
                this.displayObject,
                this.tableau.player,
                function(){
                    me.onTouch()
                }
            );
        }

        if(this.isValidate){
            this.buildText();
        }
    }

    play(){

        if(this.isDead){
            return;
        }
        super.play();
        if(!this.tween){
            this.tween = this.tableau.tweens.add({
                targets: this.group,
                y:this.props.y - 15,
                duration: 1000,
                ease: Phaser.Math.Easing.Sine.Out,
                yoyo: -1,
                repeat:-1,
            });
        }
        if(this.tween.paused){
            this.tween.resume();
        }
        this.emmiter.start();
    }

    stop(){
        super.stop();
        if(this.tween){
            this.tween.pause();

        }
        if(this.emmiter){
            this.emmiter.stop();
        }
    }

    die(){
        super.die();
        this.emmiter.remove();
        this.onDie();
    }

    onDie(){

    }
    onTouch(){

    }

    /**
     * @private
     */
    buildText(){
        this.text=this.tableau.add.text(16,-110,"VALIDER", {
                //color:"#8CC63F",
                origin: {x: 0.5, y: 0.5},
                font: "bold 16px Raleway",
                align: "center",
                fontSize: '18px',
                color: '#fff',
                stroke: '#525252',
                strokeThickness: 2,
                shadow: {color: '#8CC63F', fill: true, blur: 4, stroke: true}
            }
        );
        this.group.add(this.text);
    }

    disable(){
        this.group.setAlpha(0);
        if(this.displayObject){
            this.displayObject.body.setEnable(false)
        }

    }
    enable(){
        this.group.setAlpha(1);
        if(this.displayObject){
            this.displayObject.body.setEnable(true)
        }
    }

}