import BaseModel from "@/model/BaseModel";
import Section from "@/model/Section";

export default class TableauModel extends  BaseModel{
    constructor(dataJson) {
        super();
        /**
         * Les données des sections / questions du tableau
         * @type {Section[]}
         */
        this.sectionsData=[];
        this.setSectionsData(dataJson.sections);
        this.name=dataJson.name;
    }

    /**
     * Renvoie le tableau phaser correspondant
     * @return {TableauPhaser}
     */
    get tableauPhaser(){
        return window.phaserScenes.find(t=>t.key===this.name);
    }
    /**
     * Permet de savoir si toutes les sections / questions sont terminées
     * @returns {boolean}
     */
    get isTableauComplete(){
        for (let s of this.sectionsData){
            if(!s.complete){
                return false;
            }
        }
        return true;
    }
    /**
     * Définit les sections et questions du tableau à partir de données en provenance de l'API
     * @param {{}[]}sections
     */
    setSectionsData(sections){
        let me=this;
        for(let sectionData of sections){
            let s=new Section(sectionData,this);
            this.sectionsData.push(s);
            s.on("section-complete",function(){
                if(me.isTableauComplete){
                    window.$manager.nextScene();
                }
            })
        }
    }
    /**
     * Renvoie le modèle d'une section à partir de son nom
     * @param {string} name
     * @returns {Section}
     */
    getSectionModelByName(name){
        let s=this.sectionsData.find(sectionModel=> sectionModel.name===name || "section"+sectionModel.name===name);
        return s;
    }
    /**
     * Renvoie les questions
     * @return {Question[]}
     */
    get questions(){
        let r=[];
        for(let s of this.sectionsData){
            for(let q of s.questions){
                r.push(q)
            }
        }
        return r;
    }

    /**
     * Le nombre de question
     * @return {number}
     */
    get totalQuestions(){
        return this.questions.length;
    }

    /**
     * Le nombre de questions répondues
     * @return {Number}
     */
    get totalAnsweredQuestions(){
        return this.questions.filter(q=>q.isPassed).length;
    }

    get isCurrent(){
        return window.$manager._currentSceneKey===this.name;
    }
}