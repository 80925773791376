import Phaser from "phaser";
import BaseScene from "@/game/scenes/BaseScene";
export default class SceneMap extends BaseScene
{

    constructor(key) {
        super(key);
        this.inGame=true;
        this.music="audio-loop-map";
    }
    preload(){
        super.preload();
        //loading
        let me=this;
        this.load.on('progress', function (value) {
            me.$manager.currentTableauProps.loadingPercent=value*100;
        });
        this.load.audio(this.music,'music/Ambient-comp.mp3');
        this.load.image('map', 'game/map/map2.png');
        this.load.image('cuicui', 'game/map/cuicui.png');
        this.load.image('eolienne', 'game/map/eolienne.png');
        this.load.image('curseur', 'game/pointeur.png');
        this.load.image('ombre', 'game/ombrePointeur.png');
        this.load.image('particule', 'game/particule.png');
        this.load.image('feuille', 'game/feuille.png');


    }
    create()
    {
        super.create()
        //entrée dans le jeu
        /*
        this.input.once('pointerdown', () => {
            //window.$manager.startGame();
        });

         */
        this.map = this.add.sprite(0, 0, "map");
        this.map.setOrigin(0, 0);
        this.map.setScrollFactor(0, 0);

        this.pins=[];
        this.pins.push(this.makePin(92, 150,1,"Étape 1","Du 15 sept au 14 octobre"));
        this.pins.push(this.makePin(475,172,2,"Étape 2","Du 18 octobre au 19 novembre."));
        this.pins.push(this.makePin(531,246,3,"Étape 3","Du 23 novembre au 15 décembre"));
        this.pins.push(this.makePin(623,312,4,"Étape 4","Du 4 janvier au 3 février"));
        this.pins.push(this.makePin(820,418,5,"Étape 5","Du 8 février au 17 mars"));
        this.pins.push(this.makePin(1160,435,6,"Étape 6","Du 22 mars au 28 avril"));
        this.pins.push(this.makePin(1228,558,7,"Demi-finale","Du 10 mai au 20 mai <br><small>... et rendez-vous en juin pour la grande finale !</small>",true));

        //particules
        this.buildNeige();
        this.buildFeuilles();
        this.buildEolienne();
        this.buildCuicui();

        let pin=this.pins.find(it=>it.isCurrent===true);
        window.$manager.mapPinInfos.texte=pin.texte;
        window.$manager.mapPinInfos.titre=pin.titre;
        this.activateCurrent();

    }

    buildCuicui(){
        let m=this.add.sprite(1130,510,"cuicui")
        //m.setRotation(Phaser.Math.DegToRad(340))
        this.tweens.add({
            targets: m,
            delay:0,


            rotation:{
                ease: Phaser.Math.Easing.Sine.InOut,
                duration: 2000,
                from:Phaser.Math.DegToRad(30)*-1,
                to:Phaser.Math.DegToRad(10)*-1,
            },

            y:{
                ease: Phaser.Math.Easing.Sine.InOut,
                duration: 3000,
                from:m.y,
                to:m.y-10
            },
            repeat:-1,
            yoyo:true
        });
    }

    buildEolienne(){
        let m=this.add.sprite(390,450,"eolienne")
        this.tweens.add({
            targets: m,
            delay:0,
            duration: 1000*10,
            rotation:Phaser.Math.DegToRad(360),
            ease: Phaser.Math.Easing.Linear,
            repeat:-1,
        });
    }


    /**
     * Active le PIN courant
     */
    activateCurrent(){
        let pin=this.pins.find(it=>it.isCurrent===true);
        if(pin){
            pin.activate(true);
        }else{
            console.warn("pas de currentPin")
        }

    }
    desactivateAll(){
        for(let pin of this.pins){
            pin.activate(false);
        }
    }

    activate(num,active=true){
        let pin=this.pins.find(it=>num===it.num);
        pin.activate(true,active);
    }

    buildNeige(){
        let snowFxContainer = this.add.container();
        snowFxContainer.x = 330;
        snowFxContainer.y = 295;

        let shape1 = new Phaser.Geom.Ellipse(0, 0, 390, 40);
        let neige = this.add.particles("particule");
        //neige
        neige.createEmitter({
            frequency: 50,
            lifespan: 1500,
            quantity: 3,
            gravityX: 0,
            gravityY: 100,
            tint: [0xFFFFFF],
            rotate: {min: 0, max: 360},
            scale: {start: 0.5, end: 2, random: true},
            alpha: {start: 1, end: 0},
            emitZone: { type: 'random', source: shape1},
            speed: 1
        });

        snowFxContainer.add(neige);
        snowFxContainer.setDepth(1);
    }
    buildFeuilles(){
        let feuillesFxContainer = this.add.container();
        feuillesFxContainer.x = 600;
        feuillesFxContainer.y = 180;

        let feuilles = this.add.particles("feuille");
        feuilles.createEmitter({
            frequency: 900,
            lifespan: 3000,
            quantity: {min:1,max:3,random:true},
            rotate: {min: 0, max: 360},
            scale: {min: 0.4, max: 1.5, random: true},
            alpha: {start: 1, end: 0, ease:'Cubic.easeIn'},
            x: { min: 0, max: 50, random:true },
            y: { min: -20, max: 70, random:true},
            speedX: {min:80, max:120, random:true},
            speedY: {min:0, max:20, random:true},
        });

        feuillesFxContainer.add(feuilles);
        feuillesFxContainer.setDepth(1);
    }

    makePin(x,y,num,titre,texte,isCurrent=false){
        // eslint-disable-next-line no-unused-vars
        let me=this;
        let loopDuration=Phaser.Math.Between(1800,2000);
        let loopDelay=Phaser.Math.Between(0,100);
        let small=0.4;

        let container = this.add.container(x, y);

        //shape
        let s=me.add.rectangle(35, 40, 70, 140,0xffff00, 0.0).setInteractive( { useHandCursor: true  } );
        container.add(s);

        let scaleContainer=this.add.container();

        //ombre
        let ombre = this.add.sprite(0, 0, "ombre")
            .setDisplaySize(42,9);
        scaleContainer.add(ombre);

        let pinContainer = this.add.container(8, 0);
        //let scaleContainer = this.add.container(0, 0);

        let pin = this.add.sprite(0, 0, "curseur")
            .setOrigin(0,0)
            .setDisplaySize(54,78);

        let numero= this.add.text(24, 36, num, {
                fontFamily: 'Roboto',color:"#ffffff",
                fontWeight:900,
                align:"center",
                fontSize:18
            }
        );
        pinContainer.add(pin)
        pinContainer.add(numero);

        scaleContainer.add(pinContainer);
        container.add(scaleContainer);

        scaleContainer.setScale(small);
        scaleContainer.setPosition(35, 100);

        ombre.setPosition(0,0);
        pinContainer.setPosition(-26,-100)

        this.tweens.add({
            targets: pinContainer,
            delay:loopDelay,
            duration: loopDuration,
            y:pinContainer.y - 20,
            ease: Phaser.Math.Easing.Sine.InOut,
            yoyo: true,
            repeat:-1,
        });
        this.tweens.add({
            targets: ombre,
            delay:loopDelay,
            duration: loopDuration,
            scale: 0.5,
            ease: Phaser.Math.Easing.Sine.InOut,
            yoyo: true,
            repeat:-1,
        });
        s.on('pointerdown', function () {
            window.$manager.startGame();
        });
        s.on('pointerover', function () {
            setTimeout(function() {
                //console.log("hover")
                me.desactivateAll();
                container.activate(true);
            },10);
        });
        s.on('pointerout', function () {
            //console.log("out")
            me.desactivateAll();
            me.activateCurrent();
        });

        let tw=null;

        container.activate=function(state=true){
            if(tw){
                tw.pause();
            }
            if(state){
                tw=me.tweens.add({
                    targets: scaleContainer,
                    duration: 200,
                    scale: 1,
                    ease: Phaser.Math.Easing.Bounce.Out,
                });
                window.$manager.mapPinInfos.texte=texte;
                window.$manager.mapPinInfos.titre=titre;
                window.$manager.mapPinInfos.isCurrent=isCurrent;
            }else{
                tw=me.tweens.add({
                    targets: scaleContainer,
                    duration: 100,
                    scale:  small ,
                    ease: Phaser.Math.Easing.Sine.InOut,
                });
            }
        }

        container.num=num;
        container.isCurrent=isCurrent;
        return container;
        //pinContainer.setScale(0.5,0.5)

    }

    update(){
        console.log("sceneMap")
    }



}