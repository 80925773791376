<template>
<div class="etoile-score">
  <img v-if="flamme" src="scores/flamme.png" class="flamme">
  <span><slot></slot></span>
  <img src="scores/etoile.svg" alt=""/>
</div>
</template>

<script>
export default {
  name: "etoile-score",
  props:{
    flamme:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="less">
.etoile-score{
  user-select: none;
  position: relative;
  width: 80px;
  height: 80px;
  .flamme{

  }
  >span{
    font-weight: 800;
    position: absolute;
    font-size: 20px;
    top: 35%;
    left: 0%;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 1;
  }
  >img{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    &.flamme{
      top: -65px;
      width: 90%;
      left: 5%;
      height: auto;
    }
  }
}
</style>