import Phaser from "phaser";
import PNJ from "@/game/Objets/PNJ";
import Trigger from "@/game/Objets/Trigger";
import BlockReponse from "@/game/Objets/BlockReponse";
export default class SectionDisplay extends Phaser.Physics.Arcade.Sprite
{
    /**
     *
     * @param {TableauPhaser} tableau
     * @param {*} object Les données en prevenance de Tiled
     * @param {Section} sectionModel les datas
     */
    constructor(tableau, object,sectionModel)
    {
        super(tableau, object.x, object.y, 'sky');
        let me=this;
        /**
         * @type {TableauPhaser}
         */
        this.tableau = tableau;
        this.name = object.name;
        tableau.add.existing(this);
        tableau.physics.add.existing(this);
        this.body.allowGravity=false;
        this.setImmovable();
        this.setOrigin(0,0);
        this.alpha = 0.2;
        this.setDisplaySize(object.width, object.height*2);
        
        /**
         *
         * @type {Section}
         */
        this.section=sectionModel;

        this.section.displayObject=this;


        /**
         *
         * @type {BaseObject[]}
         */
        this.objects=[];
        /**
         *
         * @type {BaseObject[]}
         */
        this.blocksReponses=[];

        this.loop=null;
        this.isPlayerIn=false;
        tableau.physics.add.overlap(
            this,
            tableau.player,
            function(){
                me.isPlayerIn=true;
            }
        );

    }

    addObject(object,props){
        //console.warn("props",props)
        if(props){
            object.customProps=props;
        }

        let me=this;
        //PNJs
        if(this.tableau.pnjIdentifiers.indexOf(object.name)>-1){
           this.objects.push(new PNJ(object,this.tableau));
        }

        if(!this.section.complete){
            //Triggers
            if(this.tableau.triggersIdentifiers.indexOf(object.name)>-1){
                let t=new Trigger(object,this.tableau)
                this.objects.push(t);
                if(t.isValidate){
                    t.disable();
                    let q=this.section.questions[0];
                    q.on("change",function(){
                        if(q.isPlayerReponsePossible){
                            t.enable();
                        }else{
                            t.disable();
                        }
                    })
                }

                t.onTouch=function(){
                    let q=window.$manager.currentQuestion;
                    if(t.isValidate){
                        if(q && q.isPlayerReponsePossible){
                            t.die();
                            for(let r of me.blocksReponses){
                                r.die();
                            }
                            q.sendReponse();
                            me.section.on("section-complete",function(){
                                me.tableau.cameraFollow(true,false);
                            });
                        }
                    }else{
                        t.die();
                        me.section.active=true;
                    }
                }
                t.onDie=function (){}

            }
            //Blocs question
            if(["a","b","c","d"].indexOf(object.name)>-1){
                let reponse=this.section.questions[0].getReponseByAbcde(object.name);
                if(reponse){
                    let br=new BlockReponse(
                        object,
                        this.tableau,
                        reponse
                    );
                    this.blocksReponses.push(br);
                }else{
                    console.warn(`Le block réponse ${object.name} dans tiled (section ${this.section.name} / tableau ${this.tableau.key}) n'a pas de réponse dans la BDD`)
                }

            }
        }

    }

    get isPlayerIn(){
        return this._isPlayerIn;
    }
    set isPlayerIn(value){
        let me=this;
        if(value === this._isPlayerIn){
            //la valeur n'a pas changé donc on ne fait rien
            return;
        }
        //le joueur est dans le rectangle
        if(value){
            this.alpha = 0.2;
            for(let obj of this.objects){
                obj.play();
            }
            this._killLoop();
            this.loop=setInterval(function(){
                //console.log("loop")
                let r1=me.tableau.player.getBounds();
                let r2=me.getBounds();
                //console.log(r1,r2)
                if(!Phaser.Geom.Rectangle.Overlaps(r1,r2)){
                    me.isPlayerIn=false;
                }
            },1000)
        }else{
            //le joueur n'y est pas
            this._killLoop();
            this.alpha = 0.8;

            for(let obj of this.objects){
                obj.stop();
            }

        }
        this._isPlayerIn = value;
    }

    /**
     * Arrête la boucle de vérification de sortie de la zone
     * @private
     */
    _killLoop(){
        if(this.loop){
            clearInterval(this.loop);
        }
    }
}