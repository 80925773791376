<template>
<modal-game
    class="regles"
    toggler="displayRegles">

  <div class="text scrollable-content">
    <h1>Règlement du jeu « Les Olympiades 2021-2022 »</h1>

    <h2>Article 1 – Organisation</h2>
    <p>
      BCA Expertise organise un jeu concours sans condition pécuniaire dans le cadre de l’opération annuelle « Les Olympiades » qui se dérouleront de Novembre 2021 jusqu’en juin 2022.
      Nous vous invitons à lire ce document avec attention pour comprendre les règles et le déroulement des Olympiades.
    </p>

    <h2>Article 2 – Les participants aux Olympiades</h2>
    <p>
    Les Olympiades, c'est un concours ouvert à tous : opérationnels et fonctions support que vous soyez en CDI, CDD, Alternants, experts en formation etc.
    Chaque équipe sera composé des collaborateurs des UE et USE/CSC et d’une ou deux personnes des fonctions supports. Chaque équipe jouera aux Olympiades lors de sessions en ligne organisées par le manager de l’équipe. Le jeu est accessible sur notre intranet WeBCA, sur la page Olympiades où vous pourrez retrouver toutes les informations complémentaires.

    <h2>Article 3 : Etapes du jeu</h2>
    <p>
    Le jeu est découpé en plusieurs périodes : 6 séquences qui dureront chacune environ 5 semaines et une période de jeu pour les finalistes en mai avec une grande finale en juin 2022.
    </p>
    <p>
    Voici les dates des 6 premières séquences :
    </p>
    <ul>
      <li>Séquence 1 : du 13 sept au 14 octobre</li>
      <li>Séquence 2 : du 18 octobre au 19 novembre</li>
      <li>Séquence 3 : du 23 novembre au 15 décembre</li>
      <li>Séquence 4 : du 4 janvier au 3 février</li>
      <li>Séquence 5 : du 8 février au 17 mars</li>
      <li>Séquence 6 : du 22 mars au 28 avril</li>
    </ul>

    <p>
      Pendant chacune des 6 séquences, vous devrez répondre à 90 questions de culture générale et métier en fonction de son métier.
    </p>
    <p>
      Vous aurez 5 minutes par question pour répondre, attention le but est de répondre le plus rapidement et justement possible.
    </p>
    <p>
      De plus nous pourrons vous demander dans l’année de répondre à quelques questions sous forme de rédaction écrite (20/30 lignes) pour rapporter des points supplémentaires à votre équipe.
    </p>
    <p>
      A savoir, si l’une des questions proposées par l’organisateur des Olympiades vous parait fausse, vous pouvez avec votre équipe contester celle-ci. Nous effectuerons un ajustement des points manuellement.
    </p>
    <p>
      Tout au long des séquences de jeu vous obtiendrez des points selon les règles d’attribution de points et du classement par le barème d’attribution des points figurant à l’Article 4 : Barème des Olympiades.
    </p>
    <p>
      A la fin des 6 séquences, nous constituons le classement final reprenant tous les points obtenus lors de toutes les séquences pour chaque équipe UE et USE/CSC. Les 6 premières équipes de chaque filière : UE et USE / CSC formeront ensuite les équipes finalistes composées d’experts, techniciens, conseillers clients.
    </p>
    <p>
      Pour la grande finale en présentiel, chaque équipe pourra par la suite choisir son ou ses représentants pour aller en final en présentiel.
      Les 3 premières équipes seront récompensées à la hauteur de leurs efforts !
    </p>

    <h2>Article 4 – Le barème</h2>
    <p>
      Vous l’aurez bien compris, le barème prend en compte la rapidité et la justesse de la réponse !
      </p>
    <p>
      Chaque question sera sur 1000 points et vous aurez 5 minutes pour y répondre. Toute mauvaise réponse ou non-complète vous voudra 0 point. Vous aurez un bonus de 300 points supplémentaires en fonction de votre rapidité à répondre. C’est-à-dire qu’à chaque seconde perdue pour répondre c’est 1 point en moins pour votre bonus.
    </p>
    <div class="d-flex justify-center">
      <v-simple-table dense style="width: 400px">
        <template v-slot:default>
          <thead>
          <tr>
            <th>Réponse en :</th>
            <th>Nombre de points bonus</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>O secondes</td>
            <td>300</td>
          </tr>
          <tr>
            <td>1 seconde</td>
            <td>299</td>
          </tr>
          <tr>
            <td>2 secondes</td>
            <td>298</td>
          </tr>
          <tr>
            <td>10 secondes</td>
            <td>290</td>
          </tr>
          <tr>
            <td>60 secondes</td>
            <td>24</td>
          </tr>
          <tr>
            <td>120 secondes</td>
            <td>180</td>
          </tr>
          <tr>
            <td>180 secondes</td>
            <td>120</td>
          </tr>
          <tr>
            <td>240 secondes</td>
            <td>60</td>
          </tr>
          <tr>
            <td>300 secondes</td>
            <td>0</td>
          </tr>
          </tbody>
        </template>

      </v-simple-table>

    </div>

    <h2>Article 5 – Les gains</h2>
    <p>
      Lors de la finale, il y aura un podium avec les 3 premières équipes, celles-ci se verront récompensées (les représentants et leurs équipes respectives).
    </p>
    <p>
      En fonction de leur classement les 3 équipes auront un lot différent détaillé ultérieurement.
    </p>
    <p>
      Les gagnants des Olympiades devront faire partie des effectifs de l'entreprise BCA Expertise au 01/07/22.
    </p>
    <p class="text-center">
      <i>
        Bonne chance à tous !
      </i>
    </p>


  </div>

</modal-game>
</template>

<script>
import ModalGame from "@/ui/modal-game";
export default {
  name: "regles",
  components: {ModalGame}
}
</script>

<style lang="less">
.regles{
  .text{
    font-family: var(--font-family-normal);

    h1{
      text-transform: uppercase;
      text-align: center;
      color: var(--color-yellow);
      margin-bottom: 1em;
      font-weight: 900;
    }
    h2{
      opacity: 0.5;
      text-transform: uppercase;
      //color: var(--color-yellow);
      margin-top: 1.5em;
      margin-bottom: 1em;
    }
    ul{
      margin-bottom: 1em;
    }
    p,ul{
      padding-left: 2em;
      padding-right: 2em;
      font-family: var(--font-family-label);
      line-height: 1.8;
      font-size: 17px;
      font-weight: 300;
    }
    p{

    }

    .theme--light.v-data-table{
      text-transform: uppercase;
      font-family: var(--font-family-label);
      background-color: transparent;
      border-radius: 8px;
      border-color: #fff;
      color: #fff;
      td,th,tr,tbody,thead{
        background-color: transparent !important;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.5) !important;
        text-align: center;
      }
    }
    table{
      border-color: #fff;
    }
  }

}
</style>