import Reponse from "@/model/Reponse";
import Section from "@/model/Section";
import BaseModel from "@/model/BaseModel";
export default class Question extends BaseModel
{
    /**
     *
     * @param data
     * @param {Section} section
     */
    constructor(data,section)
    {
        super();
        let me=this;
        this.section=section;
        me.uid=data.questionuid;
        /**
         * Le type d'affichage de la question
         * @type {string}
         */
        this.display="";

        this.equipeReponse={
            uid: "",
            status: 2,
            elapsedSeconds: 0
        }


        /**
         * La question posée
         * @type {string}
         */
        this.texte=data.texte;
        /**
         * Plusieurs réponses possible ou pas ?
         * @type {boolean}
         */
        this.qcm=data.qcm;
        /**
         * Les réponses proposées
         * @type {Reponse[]}
         */
        this.reponses=[];
        for(let abcde of ["a","b","c","d","e"])
        {
            let texte=data[abcde];
            if(texte){
                let reponse=new Reponse(abcde,texte);
                this.reponses.push(reponse);
                reponse.on("change",function(){
                    if(!me.qcm){
                        if(reponse.checked){
                            for(let r of me.reponses){
                                r._checked=false;
                            }
                            reponse._checked=true;
                        }
                    }
                    me.setPlayerReponseByCkecked();
                })
            }

        }


        /**
         * Texte d'indication à afficher (ou pas)
         * @type {string}
         */
        this.indication="";

        /**
         * Les réponses données par le joueur sous la forme d'un tableau qui peut contenir a,b,c,d
         * @type {*[]}
         */
        this.playerReponses=[];

        /**
         * Rempli une fois que le joueur a répondu à la question est que le serveur a rendu son verdict
         * @type {null|QuestionResultat}
         */
        this.resultat=null;
        /**
         * Moment où la réponse a été affichée
         * @type {null|Date}
         */
        this.startTime=null;
        /**
         * Moment où la réponse a été envoyée
         * @type {null|Date}
         */
        this.endTime=null;

        /**
         * Le status actuel de cette question
         * Voir Question.STATUS_etc...
         * @type {number}
         */
        this._status=Question.STATUS_INACTIVE;
        this.setEquipeReponse(data.equipeReponse);

    }

    setEquipeReponse(equipeReponse){
        this.equipeReponse=equipeReponse;
        if(this.equipeReponse.status===1){
            this.startTime=new Date();
            this.startTime.setTime(this.startTime.getTime() - this.equipeReponse.elapsedSeconds * 1000 );
        }
        if(this.equipeReponse.status===2){
            this.status=Question.STATUS_PASSED;
        }
    }

    get remainingTime(){
        let diff;
        if(this.status===Question.STATUS_ACTIVE){
            let totalTime=5*60*1000;
            let targetTime = new Date( this.startTime.getTime()+totalTime);
            diff= new Date(  targetTime - window.$manager.now).getTime();
            if(diff<0){
                this.sendReponse(true);
            }
        }else{
            diff=  this.endTime - this.startTime;
        }
        return diff;
    }

    get isResultatSucces(){
        return this.resultat && !this.resultat.loading && this.resultat.correct===true;
    }
    get isResultatError(){
        return this.resultat && !this.resultat.loading && this.resultat.correct===false;
    }

    setPlayerReponseByCkecked(){
        this.playerReponses=[];
        for(let r of this.reponses){
            if(r.checked){
                this.playerReponses.push(r.abcde);
            }
        }
        this.emit("change")
    }


    get isDisplayBullesCheck(){
        return this.display===Section.DISPLAY_BULLES_CHECK;
    }
    get isDisplayBlocks(){
        return this.display===Section.DISPLAY_BLOCKS;
    }
    get isDisplayLettre(){
        return this.display===Section.DISPLAY_LETTRE;
    }
    get isDisplayTablette(){
        return this.display===Section.DISPLAY_TABLETTE;
    }
    get isDisplayPhone(){
        return this.display===Section.DISPLAY_PHONE;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        let me=this;
        if(this._status !== value){
            window.$manager.touch++;
            this._status = value;
            if(this._status===Question.STATUS_ACTIVE){
                window.$manager.updateTitle();
                me._startReponse();
            }
            if(this._status===Question.STATUS_DISPLAY_RESULT){
                setTimeout(function(){
                    //au bout de 5 secondes on fait disparaitre le résultat
                    //me.status=Question.STATUS_PASSED;
                },5000);
            }
            this.emit("status_change");
        }
    }

    _startReponse(){
        let me=this;
        me.startTime=new Date();
        window.$api.startReponse(this,
            function(data){
                me.setEquipeReponse(data.body.equipeReponse)
            },
            function(data){
                alert("start reponse erreur")
                console.error("start reponse erreur",data)
            });
    }

    /**
     * Envoie la réponse pour savoir si c'est juste ou non
     */
    sendReponse(tooLate=false){
        let me=this;
        if(this.status===Question.STATUS_ACTIVE){
            if(!tooLate && !this.isPlayerReponsePossible){
                console.warn("Merci de répondre et valider votre choix");
                return;
            }
            me.endTime=new Date();
            me.status=Question.STATUS_WAITING_RESULT;
            this.resultat=window.$api.sendReponse(this);
            this.resultat.on("complete",function(){
                me.status=Question.STATUS_DISPLAY_RESULT;
            })

        }

    }

    /**
     * Renvoie la réponse qui correponsd à a,b,c ou d
     * @param {string} abcde
     * @return {Reponse}
     */
    getReponseByAbcde(abcde){
        return this.reponses.find(r=>r.abcde===abcde);
    }

    /**
     * Renvoie true si le joueur a fait un choix suffisant pour répondre à la question
     * En d'autres termes permet de savoir si on peut valider la réponse ou non
     * @return {boolean}
     */
    get isPlayerReponsePossible(){
        return this.playerReponses.length>0 && this.status===Question.STATUS_ACTIVE;
    }

    /**
     * Renvoie true si cette question doit être affichée (si en cours, ou en cours de réponse ou affichage du résultat)
     * @return {boolean}
     */
    get isVisible(){
        switch (true){
            case this._status===Question.STATUS_ACTIVE:
            case this._status===Question.STATUS_DISPLAY_RESULT:
            case this._status===Question.STATUS_WAITING_RESULT:
                return true;
        }
        return false;

    }
    get isInactive(){
        return this._status===Question.STATUS_INACTIVE;
    }

    get isActive(){
        return this._status===Question.STATUS_ACTIVE;
    }
    get isActivable(){
        return this._status===Question.STATUS_ACTIVABLE;
    }
    get isWaitingResult(){
        return this._status===Question.STATUS_WAITING_RESULT;
    }
    get isDisplayResult(){
        return this._status===Question.STATUS_DISPLAY_RESULT;
    }
    get isPassed(){
        return this._status===Question.STATUS_PASSED;
    }


}

/**
 * La question n'est pas active
 * @type {number}
 */
Question.STATUS_INACTIVE="STATUS_INACTIVE";
/**
 * La question n'est pas active mais peut l'être si le joueur l'active
 * @type {number}
 */
Question.STATUS_ACTIVABLE="STATUS_ACTIVABLE";
/**
 * La question est active, affichée et le joueur peut répondre
 * @type {number}
 */
Question.STATUS_ACTIVE="STATUS_ACTIVE";
/**
 * Le joueur a validé sa réponse, on attend que le serveur nous donne le résultat
 * @type {number}
 */
Question.STATUS_WAITING_RESULT="STATUS_WAITING_RESULT";
/**
 * On sait si le joueur a bien répondu ou non
 * @type {number}
 */
Question.STATUS_DISPLAY_RESULT="STATUS_DISPLAY_RESULT";
/**
 * La question est passée, le joueur a répondu et a eu son résultat
 * @type {number}
 */
Question.STATUS_PASSED="STATUS_PASSED";
