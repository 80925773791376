var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-blocks"},[_c('bulle-question',{attrs:{"question":_vm.question,"time":_vm.chronoString,"texte":_vm.texteBulleQuestion,"indication":_vm.indicationBulleQuestion}}),(_vm.displayBulleReponses)?_c('div',_vm._l((_vm.question.reponses),function(r){return _c('div',{key:r.abcde,staticClass:"reponse",class:{
            'checked':r.checked
         },style:({
      position:'absolute',
      left:'calc('+r.gamePosition.x+' * var(--scale-factor-rev) * 1px)',
      top:'calc('+r.gamePosition.y+' * var(--scale-factor-rev) * 1px)'



    })},[_vm._v(" "+_vm._s(r.abcde.toUpperCase()+" - "+r.texte)+" ")])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }