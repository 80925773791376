<template>
<div class="ui-score ">
  <div>
    <label>Classement</label>
    <etoile-score
        :class="{
          'animate__animated animate__heartBeat': change === true
        }"
        @click.native="$manager.displayModal='scores';">
      {{$manager.equipe.position}}
    </etoile-score>
  </div>
  <i/>
  <div>
    <label>équipe</label>
    <span class="text-no-wrap equipe">{{$manager.equipe.name}}</span>
  </div>
  <i/>
  <div>
    <label>Questions {{$manager.equipe.totalReponsesSession}}/{{$manager.equipe.totalQuestionsSession}}</label>
    <v-progress-linear
        :value="$manager.equipe.questionProgressPercent"
        color="var(--color-yellow)"
        background-color="var(--color-blue-dark)"
        height="16px"
    />
  </div>
  <i/>
  <div>
    <label>Score</label>
    <span class="text-no-wrap txt-digital">{{ $manager.equipe.pointsFormated }}</span>
  </div>
  <i/>
  <div>
    <label>Musique</label>
    <v-switch
        style="pointer-events: auto;margin-top: -5px;margin-left: 0;"
        dense color="var(--color-gold)" v-model="$manager.musicOn">

    </v-switch>
  </div>
  <!--
  <transition name="fade">
  <div v-if="message"
      class="message" >
    {{message}}
    <v-btn x-small rounded
           class="ml-2 animate__animated animate__flash"
           color="var(--color-gold)" dark
           style="pointer-events: auto"
            @click="$manager.displayModal='scores';">
      Voir les scores
    </v-btn>
  </div>
  </transition>
  -->
</div>
</template>

<script>
import EtoileScore from "@/ui/etoile-score";
export default {
  name: "ui-score",
  components: {EtoileScore},
  data(){
    return{
      message:"",
      change:false,
      timeout:null
    }
  },
  computed:{
    classement(){
      return this.$manager.equipe.position;
    }
  },
  watch:{
    classement(){
      let me=this;
      this.change=true;
      if(me.timeout){
        clearTimeout(me.timeout);
      }
      me.timeout=setTimeout(function(){me.change=false;},1000)
    }
  }

}
</script>

<style lang="less">
.ui-score{
  color: var(--color-blue-dark);
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  min-width: 530px;
  height: 60px;
  background-color: #fff;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.16);
  border-bottom: 3px solid #DEE5E8;
  >div{
    display: flex;
    flex-direction: column;
    position: relative;
    //outline: 1px solid #eee;
    margin: 0px 10px;
    height: 40px;
    label{
      font-family: var(--font-family-label);
      color: var(--color-blue-dark);
      opacity: 0.7;
      text-transform: uppercase;
      margin-bottom: 6px;
      font-weight: 700;
      font-size: 10px;
      position: relative;
      z-index: 1;
    }

  }
  >i{
    height: 50%;
    //border-right: 1px solid #333;
    border-left: 1px solid #f0f0f0;
    margin-left: 15px;
    margin-right: 15px;
  }

  .txt-digital{
    letter-spacing: 0.2em;
    //font-weight: 500 !important;
    font-size: 12px;
    line-height: 1;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  }
  .etoile-score{
    position: absolute;
    margin-top: 5px;
    margin-left: -5px;
    cursor: pointer;
    pointer-events: auto;
    width: 70px;
    &:hover{
      filter:contrast(0.95);
    }
  }
  .equipe{
    line-height: 1;
    letter-spacing: 0.05em;
    font-weight: 800;
    margin-top: 0px;
    text-transform: uppercase;
    text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  }
  .v-progress-linear{
    border-radius: 16px;
    min-width: 200px;
    &:after{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.16);
    }
  }

  >.message{
    //outline: 1px solid red;
    font-family: var(--font-family-label);
    font-size: 12px;
    position: absolute;
    top: calc( 100% + 10px);
    left: 100px;
    width: 100%;
    display: inline;
    height: auto;
    color: var(--color-blue-dark);
    font-weight: bold;
    a{
      color: var(--color-blue-dark);;
    }
    //text-shadow: 0 0 20px #000;
  }
}
</style>