<template>
  <div style="
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
">
    <transition name="fade-up">
      <div
          v-if="visible && message && !waiting"
          class="bulle-pnj elevation-5"
          :style="{
            position:'absolute',
            right:'calc(100% - '+(bulle.x + 70 )+' * var(--scale-factor-rev) * 1px)',
            bottom:'calc(100% - '+(bulle.y - 230)+' * var(--scale-factor-rev) * 1px)'
          }"
      >
        {{message}}
        <v-btn
            v-if="$manager.messages.isClassement"
            color="var(--color-blue-bca)"
            dark
            small
            style="pointer-events:auto; margin:10px 0 5px 0; font-weight: 700; "
            @click="$manager.displayModal='scores';message=$manager.messages.message;">
          Voir le classement
        </v-btn>
      </div>
    </transition>

  </div>

</template>

<script>
import BullePNJ from "@/game/Objets/BullePNJ";

export default {
  name: "bulle-pnj",
  props:{
    bulle:{
      type:BullePNJ
    }
  },
  data() {
    return {
      message: "",
      delay:null,
      waiting:false,
    }
  },
  computed: {
    visible() {
      return this.bulle.visible;
    }
  },
  watch: {
    visible(){
      let me=this;
      if(this.visible){
        //petit delay pour s'assurer que la bulles est visible suffisament longtemps
        me.waiting=true;
        this.delay=setTimeout(function(){
          if(me.visible){
            me.waiting=false;
            me.message=me.$manager.messages.message;
          }

        },400)

      }
    },
  }

}
</script>

<style lang="less">
.bulle-pnj{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  background-color: #fff;
  border-radius: 40px;
  min-height: 40px;
  padding: 10px 30px;
  border: 4px solid #eee;
  width: 310px;
  &:after,&:before{
    position: absolute;
    right: 30px;
    top:100%;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 25px 0;
    border-color: transparent #ffffff transparent transparent;
  }
  &:before{
    border-color: transparent #eee transparent transparent;
    border-width: 0 20px 25px 0;
    transform: translateX(-6px);
  }

  font-family: var(--font-family-normal);
  font-weight: 700;
  color: var(--color-blue-dark);

}
</style>