import BaseModel from "@/model/BaseModel";

export default class Reponse extends BaseModel
{

    /**
     * Représente une possibilité de réponse à une question
     * @param {string} abcde a,b,c,d ou e
     * @param {string} texte
     */
    constructor(abcde,texte)
    {
        super();
        /**
         * Texte de la réponse
         * @type {string|*}
         */
        this.texte=texte;
        /**
         * l'identifiant de la réponse
         * @type {string}
         */
        this.abcde=abcde;

        /**
         * Pour les réponses de type block permet de donner la position x / y dans le jeu
         * @type {{x: number, y: number}}
         */
        this.gamePosition={
            x:0,
            y:0
        }
        this._checked=false;

    }

    get checked() {
        return this._checked;
    }

    set checked(value) {
        this._checked = value;
        this.emit("change")
    }
}