import Phaser from "phaser";
import GameKeyboard from "@/game/GameKeyboard";

/**
 * Classe de base pour toutes les scènes du jeu qu'il s'agisse d'un tableau ou non
 */
export default class BaseScene extends Phaser.Scene{
    constructor(key)
    {
        super(key);
        /**
         * Clé du tableau
         * @type {String}
         */
        this.key=key;
        /**
         * Pour savoir si cette scène est à inclure dans le jeu ou non
         * @type {boolean}
         */
        this.inGame=false;
        /**
         * Est un tableau ou une simple scene?
         * @type {boolean}
         */
        this.isTableau=false;
        /**
         * Pourcentage de progression de chargement / initialisation des éléments
         * @type {number}
         */
        this.loadingPercent=0;

        /**
         * Identifiant de la musique d'ambiance
         * @type {string}
         */
        this.music="";



    }
    /**
     * Raccourcis vers le manager
     * @return {Manager}
     */
    get $manager(){
        return window.$manager;
    }
    init(){
        console.log("init Tableau ",this.key);
        window.$manager.currentTableau=this;
    }
    preload(){
        this.loadingPercent=0;
        //sons
        this.load.audio('audio-ding', 'game/sons/ding.mp3');
        this.load.audio("audio-fail",'game/sons/Soft_Fail.mp3')
        this.load.audio("audio-win",'game/sons/Small_Success.mp3')
        this.load.audio("audio-tombe",'game/sons/Try_Again.mp3')
    }

    create(){

        //déclenche la classe écoutant le clavier
        this.keyboard = new GameKeyboard(this,0,0);
        this.sys.scene.scale.lockOrientation("landscape");

        // audio
        console.log("music",this.music)
        /**
         * Le son de fond
         * @type {Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound}
         * @private
         */
        this._audioLoop = this.sound.add(this.music);
        console.log("music",this.music)
        if(window.$manager.musicOn && this.music){
            this.soundAudioLoop(1);
        }

        /**
         *
         * @type {Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound}
         * @private
         */
        this._ding = this.sound.add('audio-ding');
        /**
         *
         * @type {Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound}
         * @private
         */
        this._fail = this.sound.add('audio-fail');
        /**
         *
         * @type {Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound}
         * @private
         */
        this._tombe = this.sound.add('audio-tombe');
        /**
         *
         * @type {Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound}
         * @private
         */
        this._win = this.sound.add('audio-win');



        this._soundConfigEvent={
            mute : false,
            volume : 0.4,
            rate : 1,
            detune : 0,
            seek : 0,
            loop : false,
            delay : 0
        }
    }
    /**
     * Est ce la scene en cours ?
     * @returns {boolean}
     */
    get isCurrent(){
        return window.$manager.currentScene
            && window.$manager.currentScene.key===this.key;
    }

    soundDing(){
        this._ding.play(this._soundConfigEvent);
    }
    soundFail(){
        this._fail.play(this._soundConfigEvent);
    }
    soundTombe(){
        this._tombe.play(this._soundConfigEvent);
    }
    soundWin(){
        this._win.play(this._soundConfigEvent);
    }

    soundAudioLoop(volume){
        if(volume){
            this._audioLoop.play({
                mute : false,
                volume : 0.1,
                rate : 1,
                detune : 0,
                seek : 0,
                loop : true,
                delay : 0
            })
        }else{
            this._audioLoop.stop();
        }

    }
    stopMusic(){
        console.log("stop audioloop")
        if(this._audioLoop && this._audioLoop.isPlaying){
            this._audioLoop.stop();
        }
    }

}