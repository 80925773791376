<template>
<div class="reponses">
  <template v-if="question.qcm">
    <v-checkbox
        class="check-reponse animate__animated animate__fadeIn animate__delay-1s mt-1"
        :style="{'--animate-delay':`${i*0.5+1}s`}"
        v-for="(r,i) in question.reponses"
        :key="i"
        color="var(--color-blue-dark)"
        :value="r.abcde"
        v-model="question.playerReponses"
        :label="`${r.abcde.toUpperCase()} - ${r.texte}`"
        hide-details
    />
  </template>
  <template v-else>
    <v-radio-group
        hide-details
        class="mt-0"
        v-model="question.playerReponses[0]">
    <v-radio
        class="check-reponse animate__animated animate__fadeIn animate__delay-1s"
        :style="{'--animate-delay':`${i*0.5+1}s`}"
        v-for="(r,i) in question.reponses"
        :key="i"
        color="var(--color-blue-dark)"
        :value="r.abcde"

        :label="`${r.abcde.toUpperCase()} - ${r.texte}`"
        hide-details
    />
    </v-radio-group>
  </template>

</div>
</template>

<script>
import QuestionMixin from "@/game/questions/QuestionMixin";


export default {
  name: "reponses",
  mixins:[QuestionMixin],
  data(){
    return{
      abcde:["a","b","c","d","e"]
    }
  },
  components: {}
}
</script>

<style lang="less">
.check-reponse{
  //outline: 1px solid red !important;
  //.v-input__slot,.v-radio{
   //outline: 1px solid red;
    align-items: flex-start;
    label{
      color: var(--color-blue-bca);

      font-family: var(--font-family-normal);
      font-weight: 600;
    }
    .v-input--selection-controls__input{
      margin-right: 16px;
    }

  //}

  label{
    color: #666 !important;
    margin-top: 3px;
  }

  &.v-input--is-label-active,&.v-item--active{
    label{
      color: var(--color-blue-bca) !important;
    }
  }
}
</style>