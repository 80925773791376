
export default class Stats{
    constructor(GAid="UA-207516782-1") {
        this.GAid=GAid;
    }
    pageView(path,title){

        //console.log("pageview",path,title);
        if(this.GAid && window.gtag){
            window.gtag('event', 'page_view', {
                page_path: path,
                page_title : title,
            })
        }


    }
}