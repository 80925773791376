<template>
<div class="game-component"
     v-resize="onResize"
     @click="fs"
>
  <div id="bca-town"/>

  <div ref="over-the-game"
       class="over-the-game"
       :style="{
          '--scale-factor':scaleFactor,
          '--scale-factor-rev':scaleFactorRev
       }"
  >
    <!--
    <pre style="position: absolute;color: yellow;z-index: 1000;background-color: #333;top: 50px" >
      percent {{$manager.currentTableauProps.loadingPercent}};
      isLoading {{$manager.isLoading}};
      displayLoading {{$manager.displayLoading}}:
      displayLoadingText {{$manager.displayLoadingText}}:
      isLoadingTextComplete {{$manager.isLoadingTextComplete}}
      isMap {{$manager.isMap}}
    </pre>
    -->
    <choix-personnage v-if="$manager.displayChoixPersonnage"/>

    <!--popin affichée sur la map-->
    <map-card v-if="$manager.isMap && $manager.mapPinInfos.texte"/>

    <bulle-pnj
        v-for="bulle of $manager.bullesPNJ"
        :key="bulle.pnjId"
        :bulle="bulle
    "/>

    <div class="avancez" v-if="avancez">
      <img src="game/avancez.png">
    </div>

    <!--loading-->
    <loading
        v-if="$manager.displayLoading" />
      <transition name="fade">
        <textes-progression
            v-if="$manager.displayLoadingText"/>
      </transition>

    <!--questions-->
    <question-type-switcher
        v-if="$manager.currentQuestion && $manager.currentQuestion.isVisible"
        :question="$manager.currentQuestion"
        class="question"
    />



    <!--scores ui-->
    <ui-score ref="ui-score"/>

  </div>




</div>
</template>

<script>
import BcaTown from "@/game/BcaTown";
import UiScore from "@/ui/ui-score";
import QuestionTypeSwitcher from "@/game/questions/question-type-switcher";
import MapCard from "@/ui/map-card";
import TextesProgression from "@/ui/textes-progression";
import Loading from "@/ui/loading";
import BullePnj from "@/ui/bulle-pnj";
import ChoixPersonnage from "@/ui/choix-personnage";
export default {
  name: "game-component",
  components: {
    ChoixPersonnage,
    BullePnj,

    Loading, TextesProgression, MapCard, QuestionTypeSwitcher, UiScore},
  data: () => ({
    scaleFactor:1,
    scaleFactorRev:1,
    windowSize: {
      w: 0,
      h: 0,
    },
  }),
  computed:{
    classement() {
      return this.$manager.equipe.position;
    },
    avancez(){
      return !this.$manager.currentQuestion
      && !this.$manager.displayLoading
      && !this.$manager.displayLoadingText
      && !this.$manager.isEnd
      && !this.$manager.isMap
      && this.$manager.personnage
      && this.$manager.playerCanMove
    }
  },
  watch: {
    classement(val, old) {
      this.$manager.messages.updateMessageProgression(val,old);
    }
  },
  mounted() {
    //crée le jeu
    if(window.game){
      document.location.reload();
    }
    new BcaTown();
    let me=this;
    me.$manager.on(me.$manager.EVENT_GAME_RESIZE,function(){
      me.onResize();
    })
  },
  methods:{
    onResize () {
      if(window.game){
        this.scaleFactor= window.game.scale.displaySize.width / 1408;
        this.scaleFactorRev= window.game.scale.displaySize.width / 1408 ;
        let $uiScore=this.$refs["ui-score"];
        if($uiScore){
          $uiScore=$uiScore.$el;
        }
        let $overGame=this.$refs["over-the-game"];
        let bounds=window.game.scale.canvasBounds;
        //position score par rapport au jeu
        //$uiScore.style.right=''+(bounds.left)+"px";
        //$uiScore.style.top=''+( bounds.top)+"px";
        //position question par rapport au jeu
          $overGame.style.top=`${bounds.top}px`;
          $overGame.style.left=`${bounds.left}px`;
          $overGame.style.width=`${bounds.width}px`;
          $overGame.style.height=`${bounds.height}px`;

      }

    },
    fs(){
      if (!document.fullscreenElement) {
        if(this.$manager.debug.fullScreenEnabled){
          document.documentElement.requestFullscreen();
        }

      }
    }
  }
}
</script>

<style lang="less">
.game-component{





  --scale-factor:1;
  padding: 40px;
  position: fixed;
  background-color: #DDE7EC;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;



  .over-the-game{
    //outline: 1px solid red;
    //display: none; //TODO reactiver david
    position: absolute;
    min-width: 50px;
    min-height: 50px;
    pointer-events: none;
    .ui-score{
      //display: none !important;
      user-select: none;
      position: absolute;
      top: -40px;
      left: 80px;
    }
    >.question{
      pointer-events: auto;
      position: absolute;
      width: 100%;
      height: 100%;
    }

  }
  #bca-town{
    overflow: visible !important;
    >canvas{
      background-color: #87D9E4;
      box-sizing: content-box;
      border-radius: 40px;
      box-shadow: 0px 80px 80px -60px rgba(8, 13, 35, 0.8);
      border-top: 5px solid #fff;
      border-bottom: 5px solid #6C96A9;
    }
  }

  .avancez{
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-name: avancez;
    position: absolute;
    bottom :5%;
    right: 3%;
  }
  @keyframes avancez{
    0%{
      transform: translateX(0px);
    }
    70%{
      transform: translateX(-30px);
    }
    100%{
      transform: translateX(0px);
    }
  }


}

</style>