<template>
  <div class="loading">
    <div class="text-no-wrap text txt-bold text-uppercase">
      Chargement en cours {{Math.floor($manager.currentTableauProps.loadingPercent)}}%
    </div>
    <div class="box">
      <v-progress-linear
          :value="$manager.currentTableauProps.loadingPercent"
          color="var(--color-yellow)"
          background-color="var(--color-blue-dark)"
          height="16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "loading"
}
</script>

<style lang="less">
.loading{
  --w:500px;
  position: absolute;
  top: 50%;
  left: calc( 50% - var(--w) / 2);
  width: 500px;
  >.box{
    background-color: #fff;
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.16);
    border-bottom: 3px solid #DEE5E8;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .text{
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 30px;
  }
  .v-progress-linear{
    border-radius: 16px;
    width: 100%;
    &:after{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.16);
    }
  }
}
</style>