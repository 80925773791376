<template>
<div class="timer-question">
  <div class="chrono"
       :class="{
            success:question.isResultatSucces ,
            error:question.isResultatError,
          }"
  >
    {{chronoString}}
  </div>
  <div class="question ">
    <div class="txt-bold question-txt" v-html="texteBulleQuestion"/>
    <div class="indication txt-bold"
         v-if="indicationBulleQuestion">{{indicationBulleQuestion}}</div>

    <v-btn
        elevation="10"
        v-if="question.isDisplayResult"
        class="btn-continuer"
        rounded small
        @click="question.status='STATUS_PASSED'"
        :color="question.isResultatSucces?'success':'error'" dark>Continuer</v-btn>
  </div>
</div>
</template>

<script>
import QuestionMixin from "@/game/questions/QuestionMixin";
export default {
  name: "timer-question",
  mixins:[QuestionMixin],
}
</script>

<style lang="less">
.timer-question{

  display: flex;
  align-items: flex-start;
  .chrono{
    width: calc( 67px * var(--scale-factor));
    height: calc( 67px * var(--scale-factor));
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 30px;
    background-color: var(--color-blue-bca);
    color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-digital);
    font-size: 11px;
    letter-spacing: 0.1em;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5)
                ,inset 10px 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s;
  }
  .question{
    margin-top: 20px;
    color: var(--color-blue-dark);
    line-height: 1.3;
    .question-txt{
      font-size: 120%;
    }
    .indication{
      margin-top: 0.2em;
      opacity: 0.6;
    }

  }

}
</style>