import QuestionResultat from "@/model/QuestionResultat";
const axios = require('axios').default;
export default class Api{

    constructor(apiUrl="http://bca-town-api.local/api") {
        this.apiUrl=apiUrl;
        this._intervalPing=null;
        this._equipeUid=localStorage.getItem("equipeUid");
        this.pwd="";
        this.loginid=null;
    }
    _call(action,params,cbSuccess,cbError=function(){}){
        let me=this;
        params.session="demiFinale";
        //params.tokenstring=this._getTokenString();
        params.equipeUid=this._equipeUid;
        params.pwd=this.pwd;
        params.loginid=this.loginid;
        axios.post(`${this.apiUrl}/${action}`,
            params
        )
            .then(function (response) {
                //console.log(response.data);
                if(response.data){
                    if(response.data.body.equipe){
                        window.$manager.equipe.setJson(response.data.body.equipe);
                        if(!me.loginid){
                            me.loginid=window.$manager.equipe.loginid;
                        }
                        if(window.$manager.equipe.loginid && window.$manager.equipe.loginid !== me.loginid){
                            console.warn("bad login id",window.$manager.equipe.loginid,me.loginid);
                            alert("Vous allez être déconnecté car votre équipe s'est connectée depuis un autre poste entre temps");
                            document.location.reload();
                        }
                    }
                    if(!response.data.success){
                        console.error('api error',action,response.data);
                        cbError(response.data);
                    }else{
                        cbSuccess(response.data);
                    }
                }else{
                    cbError(response.data);
                }
            })
            .catch(function (error) {
                console.error(error);
                cbError(error);
            })
            .then(function () {
                // always executed
            });
    }
    get equipeUid() {
        return this._equipeUid;
    }
    set equipeUid(value) {
        localStorage.setItem("equipeUid",value);
        this._equipeUid = value;
    }


    /**
     * Renvoie la liste des équipes
     * @param cb
     */
    listEquipes(cb=function(data){console.log(data)}){
        this._call("game/listEquipes",{},cb)
    }
    /**
     * Permet de se logguer avec une equipe
     * @param equipeUid
     * @param pwd
     * @param success
     * @param error
     */
    loginEquipe(equipeUid,pwd,success,error){
        let me=this;
        me.equipeUid=equipeUid;
        me.pwd=pwd;
        this._call("game/loginEquipe",{
            uid:equipeUid,
            pwd:pwd
        },function(data){
            if(success){
                me.loginid=data.body.equipe.loginid;
                success(data)
            }
        },function(data){
            if(error){
                error(data)
            }
        })
    }
    pingEquipe(){
        this._call("game/pingEquipe",{
            // eslint-disable-next-line no-unused-vars
        },function(data){
            //console.log("ping Equipe Ok",data)
            // eslint-disable-next-line no-unused-vars
        },function(data){
            //console.log("ping Equipe NOk",data)
        })
    }
    /**
     * Permet d'effacer toutes les réponses d'une equipe
     * @param success
     * @param error
     */
    resetGameEquipe(success,error){
        this._call("game/resetGameEquipe",{},function(data){
            if(success){
                success(data)
            }
        },function(data){
            if(error){
                error(data)
            }
        })
    }

    /**
     * Dit qu'on commence a répondre à une question
     * @param {Question} question
     * @param {function} onSuccess
     * @param {function} onError
     */
    startReponse(question,onSuccess,onError){
        console.log("startReponse",question)
        this._call('game/startReponse',{
            questionuid:question.uid,
        },onSuccess,onError);
    }
    /**
     * Envoie la réponse à une question donnée
     * @param {Question} question
     * @returns {QuestionResultat}
     */
    sendReponse(question){
        console.log("sendReponse",question)
        let obj=new QuestionResultat();
        this._call('game/sendReponse',{
            questionuid:question.uid,
            abcde:question.playerReponses.join(","),
        },function(data){
            obj.loading=false;
            obj.message=data.messages[0].message;
            obj.correct=true;
            obj.emit("complete");
        },function(data){
            obj.loading=false;
            obj.message=data.errors[0].message;
            obj.correct=false;
            obj.emit("complete");
        })
        return obj;
    }

    /**
     * Efface la réponse à une question donnée
     * @param {Question} question
     * @returns {QuestionResultat}
     */
    deleteReponse(question,cb){
        console.log("deleteReponse",question)
        let obj=new QuestionResultat();
        this._call('game/deleteReponse',{
            questionuid:question.uid,
        },function(data){
            console.log("q deleted ok",data)
            cb(data)
        },function(data){
            console.log("q deleted NOK",data)
            cb(data)
        })
        return obj;
    }

}