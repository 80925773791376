var EventEmitter = require('event-emitter-es6');
export default class QuestionResultat extends EventEmitter{

    /**
     * Représente le résultat de la réponse à une question
     */
    constructor() {
        super();
        /**
         * Si false c'est que la réponse est prête
         * @type {boolean|null}
         */
        this.loading=null;
        /**
         * Correct ou pas?
         * @type {boolean|null}
         */
        this._correct=null;
        /**
         * Message renvoyé au joueur (félicitation, bonne réponse, etc...)
         * @type {string}
         */
        this.message="";
        /**
         * Points gagnés
         * @type {number}
         */
        this.points=0;
        /**
         * Nombre de points total du joueur à l'issue de cette réponse
         * @type {number}
         */
        this.playerTotalPoints=0;
    }
    get correct() {
        return this._correct;
    }

    set correct(value) {
        if(value !== this._correct){
            if(window.$manager.currentTableau){
                if(value){
                    window.$manager.currentTableau.soundWin();
                }else{
                    window.$manager.currentTableau.soundFail();
                }
            }

        }
        this._correct = value;

    }

}