export default class MessagesManager{
    constructor() {
        this.messageProgression="";
        this.messages=[
            "Utilisez les flèches de votre clavier pour vous déplacer",
        ];
        this.encouragements=[
            "Allez continuez !",
            "On avance !",
        ]
        this.astuces=[
            "plus vous répondez vite, plus vous gagnez de points",
            "évitez de quitter le jeu au milieu d'une question, vous perdriez des points.",
            "chaque seconde perdue pour répondre c’est 1 point en moins pour votre bonus",
            "tant qu'on ne vous pose pas de question, le chrono ne tourne pas, profitez-en pour faire une pause ;)",
            "Vous pouvez quitter le jeu à tout moment et y revenir plus tard sans pénalité."
        ]
        this.astucesSansPreffixe=[
            "Si l’une des questions proposées par l’organisateur des Olympiades vous parait fausse, vous pouvez avec votre équipe contester celle-ci.",
            "Pour la grande finale chaque équipe pourra choisir son ou ses représentants",
            "Les Olympiades, c'est un concours ouvert à tous : opérationnels et fonctions support que vous soyez en CDI, CDD, Alternants, experts en formation etc.",
            "La grande finale se déroulera en présentiel en juin 2022",
            "Les 6 premières équipes de chaque filière : UE et USE / CSC formeront des équipes finalistes composées d’experts, techniciens et conseillers clients."
        ]
        this.oneTime=[];

        this.messageProgression="";
        this.isClassement=false;
    }

    _remplir(){
        for(let t of this.encouragements){
            this.messages.push(t)
        }
        for(let t of this.astuces){
            this.messages.push(this._astuce + t)
        }
        for(let t of this.astucesSansPreffixe){
            this.messages.push(t)
        }
        while(this.oneTime.length>0){
            this.messages.push(this.oneTime.pop())
        }
        this.messages=this.messages.sort(() => 0.5 - Math.random());
    }


    get message(){
        if(this.messageProgression){
            this.messages.unshift(this.messageProgression);
            this.messageProgression="";
            this.isClassement=true;
        }else{
            this.isClassement=false;
        }
        let ret="";
        if(this.messages.length>0){
            ret = this.messages.shift();
        }else{
            this._remplir();
            ret = this.messages.shift();
        }
        console.log("get message",ret)
        return ret;
    }


    updateMessageProgression(val,old){
        let m="";
        let places = Math.abs(val - old);
        if(old===0){
            return "Vous faites honneur au classement."
        }
        if (old < val) {
            m=this._humiliation;
            switch (true) {
                case old === 1:
                    m += "on vous a volé la 1ère place";
                    break;
                case old <= 3:
                    m += "vous n'êtes plus sur le podium";
                    break;
                default:
                    if (places > 1) {
                        m += `vous venez de perdre ${places} places dans le classement`;
                    } else {
                        m += "on vous a dépassé dans le classement"
                    }
            }

        }
        if (old > val) {
            m=this._bravo;
            switch (true) {
                case val === 1:
                    m += "vous êtes en pôle position!";
                    break;
                case val <= 3:
                    m += "vous êtes dans le TOP 3";
                    break;
                case val <= 5:
                    m += "vous êtes dans le TOP 5";
                    break;
                default:
                    if (places > 1) {
                        m += `vous gagnez ${places} places dans le classement`;
                    } else {
                        m += "vous avez progressé dans le classement";
                    }
            }
        }
        this.messageProgression=m;
        return m;
    }

    get _bravo(){
        return this.randArray([
            "Génial, ","Super! ","Bravo! ","Top, "
        ]);
    }
    get _humiliation(){
        return this.randArray([
            "Dommage, ","Attention... "
        ]);
    }
    get _astuce(){
        return this.randArray([
            "Petite astuce : ","Conseil, ","Pensez-y, "
        ]);
    }

    randArray(txt){
        return txt[Math.floor(Math.random() * txt.length)];
    }

}