import BaseScene from "@/game/scenes/BaseScene";

export default class Fin extends BaseScene{
    constructor(key) {
        super(key);
        this.inGame=true;
        this.music="audio-loop-tableau";
    }
    preload() {
        super.preload();
        this.load.audio(this.music,'music/EnjoyExperience.mp3')
    }

    create() {
        super.create();
    }
}