<template>
  <v-navigation-drawer
      :stateless="true"
      v-model="$manager.debugDrawerOpen"
      right  absolute temporary app hide-overlay
      width="400"
  >
    <template slot="prepend">
      <v-toolbar>
        <v-spacer/>
        <v-icon
            @click.stop="$manager.debugDrawerOpen = !$manager.debugDrawerOpen" >
          mdi-close
        </v-icon>
      </v-toolbar>

    </template>

    <v-list dense max-width="800">
      <v-subheader>Connecté en tant que...</v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$manager.equipe.name}}</v-list-item-title>
          <v-list-item-subtitle>{{$manager.equipe.unite}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
              small rounded
              color="error"
              :disabled="!$manager.equipe.uid"
              @click="resetGame">Recomencer le jeu</v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-subheader>Scènes / Tableaux</v-subheader>

      <template  v-for="scene of $manager.phaserScenes">
        <v-list-item
            :key="scene.key"
            @click="$manager.loadScene(scene.key)"
        >
          <v-list-item-avatar :color="scene.tableauModel?'indigo':'grey'">
            <span class="white--text">T</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{scene.key}}
              <span v-if="scene.tableauModel">({{scene.tableauModel.sectionsData.length}}) </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="scene.tableauModel && scene.tableauModel.isTableauComplete">complete</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text v-if="scene.tableauModel">
              {{scene.tableauModel.totalAnsweredQuestions}} / {{scene.tableauModel.totalQuestions}}
            </v-list-item-action-text>
            <v-icon color="success" v-if="scene.isCurrent">mdi-check-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!--section-->
        <template  v-if="scene.isTableau && scene.tableauModel">
          <template v-for="section of scene.tableauModel.sectionsData">
            <v-list-item :key="section.uid" @click="section.placePlayer(false)">
              <v-list-item-avatar color="grey">
                <span class="white--text">S</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{section.name}}</v-list-item-title>
                <v-list-item-subtitle>{{section.isPlayerIn?"in":"OUT"}} | {{section.active?"active":"NOT active"}} | {{section.complete?'complete':'NOT complete'}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success"
                        v-if="section.active">
                  mdi-check-circle
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <!--question-->
            <template  v-for="question of section.questions">
              <v-list-item :key="question.uid">
                <v-list-item-avatar color="pink">
                  <span class="white--text">Q</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{question.texte}}</v-list-item-title>
                  <v-list-item-subtitle>{{question.status}} | {{question.isVisible?'visible':'INvisible'}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                      v-if="question.isPassed"
                      @click="deleteQuestion(question);"
                      x-small color="error">effacer</v-btn>
                  <v-icon color="success"
                          v-if="question.isVisible">
                    mdi-check-circle
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <!--
              <template  v-for="reponse of question.reponses">
                <v-list-item :key="reponse.uid">
                  <v-list-item-avatar color="green" size="32">
                    <span class="white--text">{{reponse.abcde}}</span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{reponse.texte}}</v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="success"
                            v-if="reponse.checked">
                      mdi-check-circle
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              -->
            </template>

          </template>
        </template>

      </template>

    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "debug-drawer",
  components: {},
  data () {
    return {
      drawer: null,
    }
  },
  mounted() {
  },
  methods:{
    deleteQuestion(question){
      let me=this;
      me.$api.deleteReponse(question,
          function(){
            //location.reload();
          },
          function(err){
            console.error(err)
          }
      )
    },
  resetGame(){
    let me=this;
    console.log("resetGame");
    me.$api.resetGameEquipe(
        function(){
          location.reload();
        },
        function(){
          location.reload();
        }
    )
  }
  }
}
</script>

