import { format } from 'date-fns'
import Question from "@/model/Question";
export default {
    props:{
      question:{
          type:Question
      }
    },
    methods:{},
    computed:{
        /**
         * Le temps passé pour répondre
         * @return {string}
         */
        chronoString(){
            let diff=this.question.remainingTime;
            return format(diff,"mm:ss")
        },
        texteBulleQuestion(){
            if(this.question.isActive){
                return this.question.texte;
            }
            if(this.question.isWaitingResult){
                return "Veuillez patienter...";
            }
            if(this.question.resultat){
                return  this.question.resultat.message;
            }
        },
        indicationBulleQuestion(){
            if(this.question.isActive){
                return this.question.indication;
            }
            if(this.question.isWaitingResult){
                return "Nous vérifions si votre réponse est juste";
            }
            return "";
        },
        /**
         * Faut il afficher ou non les réponses possibles dans le HTML?
         * (Parfois ces réponses sont gérées dans Phaser)
         * @return {boolean}
         */
        displayBulleReponses(){
            if(this.question.isActive){
                return true;
            }
            return false;
        }

    }
}