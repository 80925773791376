/**
 * Un objet qui écoute les touches du clavier et mouvements sur le pad et qui influent le déplacement du joueur
 */
import Phaser from "phaser";

export default class GameKeyboard extends Phaser.GameObjects.Container
{
    constructor(scene, x, y)
    {
        super(scene, x, y);
        //console.log("GameKeyBoard")
        scene.add.existing(this);

        this.scene = scene;
        this.cursors = scene.input.keyboard.createCursorKeys();

        scene.input.keyboard.on('keydown', function(kevent)
        {
            if(this.scene.player) {
                switch (kevent.keyCode)
                {
                    case Phaser.Input.Keyboard.KeyCodes.S:
                    case Phaser.Input.Keyboard.KeyCodes.RIGHT:
                        this.scene.player.directionX = 1;
                        this.scene.arrowRightUnpressed = false;
                        this.scene.arrowRightPressed = true;
                        break;

                    case Phaser.Input.Keyboard.KeyCodes.Q:
                    case Phaser.Input.Keyboard.KeyCodes.LEFT:
                        this.scene.player.directionX = -1;
                        this.scene.arrowLeftPressed = true;
                        this.scene.arrowRightUnpressed = false;
                        break;

                    case Phaser.Input.Keyboard.KeyCodes.Z:
                    case Phaser.Input.Keyboard.KeyCodes.SPACE:
                    case Phaser.Input.Keyboard.KeyCodes.UP:
                        this.scene.player.directionY = -1;
                        break;
                }
            }
        });
        scene.input.keyboard.on('keyup', function(kevent)
        {
            if(kevent.keyCode === Phaser.Input.Keyboard.KeyCodes.ENTER){
                window.$manager.keyEnterPressed();
            }
            if(this.scene.player) {
                switch (kevent.keyCode)
                {
                    case Phaser.Input.Keyboard.KeyCodes.S:
                    case Phaser.Input.Keyboard.KeyCodes.RIGHT:
                        this.scene.player.directionX = 0;
                        this.scene.arrowRightUnpressed = true;
                        this.scene.arrowRightPressed = false;
                        break;

                    case Phaser.Input.Keyboard.KeyCodes.Q:
                    case Phaser.Input.Keyboard.KeyCodes.LEFT:
                        this.scene.player.directionX = 0;
                        this.scene.arrowLeftUnpressed = true;
                        this.scene.arrowLeftPressed = false;
                        break;

                    case Phaser.Input.Keyboard.KeyCodes.Z:
                    case Phaser.Input.Keyboard.KeyCodes.SPACE:
                    case Phaser.Input.Keyboard.KeyCodes.UP:
                        this.scene.player.directionY = 0;
                        break;
                }
            }
        });

    }

}