<template>
<button class="btn-relief">
  <span class="elevation-3">
      <slot/>
  </span>

</button>
</template>

<script>
export default {
  name: "btn-relief"
}
</script>

<style lang="less">
    .btn-relief{
      --bg-color:#fff;
      --fg-color:var(--color-blue-bca);
      &.active{
        --bg-color:linear-gradient(180deg, #F15022 0%, #FDF006 100%);
        --fg-color:#fff;
      }
      --size:45px;
      &.circle{
        >span{
          width: var(--size);
          height: var(--size);
          padding: 0;
        }
      }
      &:hover{
        filter:brightness(0.99);
      }
      user-select: none;
      cursor: pointer;
      background: #fff;
      border-radius: var(--size);
      padding: 8px;
      >span{
        pointer-events: none;
        background: var(--bg-color);
        border-radius: var(--size);
        display: flex;
        height: var(--size);

        justify-content: center;
        align-items: center;
        line-height: 1;

        font-family: var(--font-family-digital);
        letter-spacing: 0.1em;
        padding: 0px 30px 3px 30px;
        color: var(--fg-color);

        font-family: var(--font-family-normal);
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0px 30px 0px 30px;
        letter-spacing: normal;

      }
    }
</style>