<template>
  <div class="bulle-question">
    <timer-question
        :question="question"/>
  </div>

</template>

<script>
import QuestionMixin from "@/game/questions/QuestionMixin";
import TimerQuestion from "@/game/questions/components/timer-question";

export default {
  name: "bulle-question",
  components: {TimerQuestion},
  mixins:[QuestionMixin],
  props:{
    time:{
      type:String,
      default:"00:00"
    },
    texte:{
      type:String,
    },
    indication:{
      type:String,
    }
  },
  computed:{
    chronoColor(){
      if(this.question.isResultatError){
        return "#ff0000";
      }
      if(this.question.isResultatSucces){
        return "#009900";
      }
      return "#0A648E";
    }
  }
}
</script>

<style lang="less">
 .bulle-question {
   background-color: #fff;
   border-radius: 80px;
   min-height: 40px;
   padding: 10px 10px;
   padding-right: 80px;
   border: 4px solid #eee;
   .timer-question{
     .question{
       margin-top: 0;
     }
     align-items: center;
   }
   &:after,&:before{
     position: absolute;
     right: 70px;
     top:100%;
     content: "";
     width: 0;
     height: 0;
     border-style: solid;
     border-width: 0 30px 35px 0;
     border-color: transparent #ffffff transparent transparent;
   }
   &:before{
     border-color: transparent #eee transparent transparent;
     border-width: 0 30px 35px 0;
      transform: translateX(-6px);
   }
   .btn-continuer{
     position: absolute;
     z-index: 1;
     margin-left: 0;
     margin-right: 0;
     right:-50px;
     bottom: -15px;
   }
 }
</style>