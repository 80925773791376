<template>
<div class="textes-progression" >
  <video src="/loop-road.mp4" autoplay loop muted></video>

  <anim-textes @ended="finish" :pause-end="$manager.isEnd">
    <!-- Vous avez trminé -->
    <div v-if="isEnd" class="slide">
      <div class="phrase">
        <div class="y">bravo !</div>
      </div>
      <div class="phrase">
        <small> vous avez </small>répondu
      </div>
      <div class="phrase">
        <small>à toutes les</small> questions
      </div>
    </div>

    <!-- vous avez répondu à x questions -->
    <div v-if="showProgression && !isEnd" class="slide">
      <div class="phrase">
        <small>Vous avez</small> répondu <small>à</small>
      </div>
      <div class="phrase">
        <div class="y">{{$manager.equipe.totalReponses}} QUESTIONS</div>
        <small>sur</small> {{$manager.equipe.totalQuestions}}
      </div>
    </div>
    <div v-if="showProgression" class="slide">
      <div class="phrase">
        <small>votre</small> taux <small>de</small>
        <div class="y">réussite</div>
      </div>
      <div class="phrase">
        <small>est de</small>
        <div class="y">{{$manager.equipe.tauxReponse}}%</div>
      </div>
    </div>
    <div v-if="showProgression" class="slide">
      <div class="phrase">
        <small>votre</small>
        <div class="y">temps </div>
        <small>de réponse</small>
      </div>
      <div class="phrase">
        <small>moyen est de</small>
        <div class="y">
          {{$manager.equipe.tempsReponse}}
          <small>secondes</small>
        </div>

      </div>
    </div>
    <div v-if="showProgression" class="slide">
      <div class="phrase">
        <small>vous êtes en</small>
        <div class="y">{{$manager.equipe.position}}
          <small>{{$manager.equipe.position===1?'ère':'ème'}}</small>
        </div>
        <small>position</small>
      </div>
      <div class="phrase">
        <small>au </small>
        <div class="y">classement</div>
        <small>général</small>
      </div>
    </div>

    <!--vous êtes prêts?-->
    <div v-if="!showProgression && !isEnd"
         class="slide">
      <div class="phrase">
        Vous êtes
      </div>
      <div class="phrase">
        <div class="y">prêts ?</div>
      </div>
    </div>

    <!--c'est parti !-->
    <div  v-if="!isEnd" class="slide">
      <div class="phrase">C'est</div>
      <div class="phrase">
        <div class="y">Parti !</div>
      </div>
    </div>

    <!-- RDV le... -->
    <div v-if="isEnd" class="slide">
      <div  class="phrase">Rendez-vous</div>
      <div class="phrase">
        <small>à la</small>
        <div class="y">Finale</div>
      </div>
    </div>
  </anim-textes>

  <div class="buttons">
    <btn-relief
        v-if="!$manager.isLoading && !isEnd"
        @click.native="$manager.isLoadingTextComplete=true;"
    >Jouer</btn-relief>
    <span
        v-else-if="$manager.currentTableauProps.loadingPercent<100"
        style="
        font-family: var(--font-family-label);
        font-weight: bold;
        opacity: 0.5;
        color:#fff;
        font-size: 10px;" >
      CHARGEMENT {{Math.min(100,Math.floor($manager.currentTableauProps.loadingPercent))}}%
    </span>
    <btn-relief
        @click.native="$manager.displayModal='scores'"
    >Classement
    </btn-relief>
  </div>



</div>
</template>

<script>

import BtnRelief from "@/ui/btn-relief";
import AnimTextes from "@/ui/anim-textes";
export default {
  name: "textes-progression",
  components: {AnimTextes, BtnRelief},

  computed:{
    showProgression(){
      return this.$manager.equipe.totalReponses>1;
    },
    isEnd(){
      return this.$manager.isEnd;
    }
  },
  methods:{
    finish(){
      if(!this.$manager.isEnd){
        this.$manager.isLoadingTextComplete=true;
      }
    }

  }
}
</script>

<style lang="less">
.textes-progression{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 40px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #6C96A9;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  //background-image: url("/game/sky.jpg");

  >video{
    position: absolute;
    //display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;

  }

  .buttons{
    position: absolute;
    bottom: 20px;
    right: 20px;
    >*{
      pointer-events: auto;
      margin: 20px;
    }
  }

}
</style>