
import TableauPhaser from "./TableauPhaser";

export default class DemiFinaleCommunes1 extends TableauPhaser
{
    preload(){
        super.preload();
        this.load.tilemapTiledJSON('DemiFinaleCommunes1', 'game/tilemap/DemiFinaleCommunes1.json');
    }
    create(){
        super.create('DemiFinaleCommunes1');
    }
}