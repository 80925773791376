import Question from "@/model/Question";
import DebugConfig from "../DebugConfig";

import Equipe from "@/Equipe";
import TableauModel from "@/model/TableauModel";
import MessagesManager from "@/MessagesManager";
import Stats from "@/model/Stats";

const EventEmitter = require('event-emitter-es6');



export default class Manager extends EventEmitter{


    constructor() {
        super();
        let me=this;
        let pack=require("../package.json");
        this.stats=new Stats();
        this.version=pack.version;
        this.projectName=pack.name;
        this.projectDesc=pack.description;
        /**
         *
         * @type {TableauModel[]}
         */
        this.tableauxModels=[];
        /**
         * Le drawer est il ouvert ou non?
         * @type {boolean}
         */
        this.debugDrawerOpen=false;
        /**
         *
         * @type {Equipe[]}
         */
        this.listeEquipes=[];

        /**
         * Si true la camera est en déplacement et il faut bloquer le mouvement du joueur
         * @type {boolean}
         */
        this.cameraIsMoving=false;

        /**
         * Modal a afficher (ou pas) regles ou scores
         * @type {string}
         */
        this.displayModal="";


        this.EVENT_GAME_RESIZE="EVENT_GAME_RESIZE";

        /**
         * Config de debug
         * @type {DebugConfig}
         */
        this.debug=new DebugConfig();

        /**
         * Le temps actuel (mis à jour et réactif)
         * @type {Date}
         */
        this.now=new Date();
        setInterval(function(){
            me.now=new Date();
        },500)

        this.touch=0;

        this.mapPinInfos={
            titre:"",
            texte:"",
            isCurrent:false
        }

        this._musicOn=localStorage.getItem("musicOn") !== "off";

        this._uididx=0;
        /**
         *
         * @type {String}
         */
        this._currentSceneKey="";
        /**
         *
         * @type {Equipe}
         */
        this.equipe=new Equipe();
        /**
         * Personnage choisi
         * @type {string}
         */
        this.personnage="";
        /**
         * L'animation du loading est elle terminée?
         * @type {boolean}
         */
        this.isLoadingTextComplete=true;


        this.currentTableauProps={
            loadingPercent:0
        }

        /**
         *
         * @type {BullePNJ[]}
         */
        this.bullesPNJ=[];
        /**
         *
         * @type {MessagesManager}
         */
        this.messages=new MessagesManager();


    }

    get displayChoixPersonnage(){
        if(this.isEnd){
            return false;
        }
        if(!this.personnage){
            return true
        }
        return false;
    }

    /**
     * Est-ce la fin du jeu?
     * @returns {boolean}
     */
    get isEnd(){
        //return true;
        return this.equipe
            && this.equipe.totalReponsesSession >= this.equipe.totalQuestionsSession
            && (!this.currentQuestion)
    }

    /**
     * Est-on sur la map ?
     * @returns {boolean}
     */
    get isMap(){
        return this._currentSceneKey
            && !this.displayChoixPersonnage
            && this._currentSceneKey==="Map";
    }

    /**
     * Est on en mode loading
     * @returns {boolean}
     */
    get isLoading(){
        return  this.currentTableauProps.loadingPercent<100
    }

    get displayLoading(){
        return this.isLoading
            && !this.displayLoadingText
            && this.personnage !== ""
            ;
    }

    get displayLoadingText(){
        if( this.isLoading || this.isEnd || !this.isLoadingTextComplete){
            if(!this.isMap ){
                if(this.personnage){
                    return true
                }
                if(this.isEnd){
                    return true;
                }

            }
        }
        return false;
    }

    get musicOn() {
        return this._musicOn;
    }

    set musicOn(value) {
        this._musicOn = value;
        localStorage.setItem('musicOn',value?'on':'off');
        this.getSceneByKey(this._currentSceneKey).soundAudioLoop(value?1:0);
    }

    /**
     * Renvoie un tableau model à partir de la key / name
     * @param keyName
     * @return {TableauModel}
     */
    getTableauModel(keyName){
        return this.tableauxModels.find(t=>t.name===keyName);
    }

    /**
     * Charge les équies et remplis le tableau des équipes
     */
    loadEquipes(){
        let me=this;
        window.$api.listEquipes(function(data){
            me.listeEquipes=[];
            for(let it of data.body.equipes){
                me.listeEquipes.push(
                    new Equipe(it)
                )
            }
        })
    }

    /**
     * En fonction des données reçues par l'API va activer ou pas certains tableaux et leur attribuer les sections / questions
     * @param {Object[]} tableaux
     */
    initTableauxForEquipe(tableaux){
        //pour chaque tableau et chaque section...
        for(let tableauData of tableaux){
            if(!this.getSceneByKey(tableauData.name)){
                console.warn(`le tableau ${tableauData.name} renvoyé par l'api n'existe pas dans le jeu`)
            }
            this.tableauxModels.push(new TableauModel(tableauData))
        }
    }
    /**
     * lance un ping en boucle qui permet de savoir si...
     * - on est toujours connecté //TODO checker si un autre ordi est pas connecté au même compte
     * - on a progressé ou pas dans le classement
     */
    initPingEquipe(){
        this.messages.oneTime.push("L'équipe "+this.equipe.name+ " est la meilleure !");
        setInterval(function(){
            window.$api.pingEquipe()
        },1000 * 30);
    }



    /**
     * Arrête les scenes qui ne sont pas la scène en cours
     * @private
     */
    _stopOtherScenes(){
        let me=this;
        setTimeout(function(){
            for(let s of window.phaserScenes){
                if(s.key !== me._currentSceneKey && s.scene.isActive()){
                    console.log("stop",s.key)
                }
                //ce qu' il faut arrêter qui ne s' arrête pas tout seul :\
                if(s.key !== me._currentSceneKey){
                    s.stopMusic();
                }

            }
        },1000)

    }

    /**
     *
     * @returns {BaseScene|TableauPhaser[]}
     */
    get phaserScenes(){
        return window.phaserScenes;
    }


    startGame(){
        console.log("startGame")
        if(this.displayChoixPersonnage){
            console.log("choississez un perso avant")
            return;
        }
        for(let scene of this.phaserScenes){
            if(scene.tableauModel){
                for(let s of scene.tableauModel.sectionsData){
                    if(!s.complete){
                        console.log("startGame section",s.name)
                        this.loadScene(scene.key);
                        s.placePlayer();
                        return;
                    }
                }
            }
        }
        if(this.isEnd){
            this.loadScene("fin")
        }
    }

    /**
     * Charge la scène suivante
     */
    nextScene(){
        console.log("nextScene")
        //let isNext=false;
        for(let t of this.phaserScenes){
            if(t.tableauModel && !t.tableauModel.isTableauComplete){
                this.loadScene(t.key);
                return;
            }
            /*
            if(isNext && t.tableauModel){
                this.loadScene(t.key);
                return;
            }
            if(this._currentSceneKey===t.key){
                isNext=true;
            }
            */
        }
        console.warn("nextScene failed")
    }

    /**
     *
     * @param {string} key
     * @returns {TableauPhaser}
     */
    getSceneByKey(key){
        return window.phaserScenes.find(t=>t.key===key);
    }

    /**
     * Charge le tableau donné
     * @param {string} key
     */
    loadScene(key){
        console.log("--------------------loadScene-------------------",key)
        //if(this.equipe.totalReponses>5){
            this.isLoadingTextComplete=false;
        //}
        let me=this;
        setTimeout(function(){
            me.cameraIsMoving=false;
        },100)
        if(this._currentSceneKey){
            this.getSceneByKey(this._currentSceneKey).scene.start(key);
        }else{
            console.warn("pas de _currentScene")
        }
    }

    /**
     * Renvoie un identifiant unique
     * @returns {String}
     */
    getUid(){
        return 'uid-'+String(this._uididx++);
    }

    /**
     * Dit si le joueur peut bouger en fonction de l'état du jeu
     * @returns {boolean}
     */
    get playerCanMove()
    {
        if(this.cameraIsMoving){
            return false;
        }
        if(!this.currentQuestion){
            return true;
        }
        if(this.currentSection.display === "DISPLAY_BLOCKS"){
            switch (this.currentQuestion.status)
            {
                case Question.STATUS_WAITING_RESULT:
                case Question.STATUS_DISPLAY_RESULT:
                    return false;
            }
            return true;
        }
        switch (this.currentQuestion.status)
        {
            case Question.STATUS_ACTIVE:
            case Question.STATUS_WAITING_RESULT:
            case Question.STATUS_DISPLAY_RESULT:
                return false;
        }
        return true;
    }

    /**
     * Met à jour le titre
     */
    updateTitle(){
        let t=[];
        if(this._currentSceneKey){
            t.push(this._currentSceneKey)
        }
        if(this.currentSection){
            t.push(this.currentSection.name);
        }
        if(this.currentQuestion){
            t.push(this.currentQuestion.texte);
        }
        document.title=t.join(" / ");
        if(this.equipe){
            t.push("equipe-"+this.equipe.numero)
        }
        this.stats.pageView(t.join("/"));
    }

    /**
     * Renvoie la scène en cours
     * @return {BaseScene}
     */
    get currentScene(){
        return this.getSceneByKey(this._currentSceneKey);
    }
    /**
     * Renvoie le tableau en cours (il faut que ce soit un tableau)
     * @return {TableauPhaser|null}
     */
    get currentTableau() {
        let sc=this.currentScene;
        if(sc && sc.isTableau){
            return sc;
        }
        return null;
    }

    set currentTableau(value) {
        this._currentSceneKey = value.key;
        window.tableau=value;
        this.updateTitle();
        this._stopOtherScenes();
    }

    /**
     * Renvoie la section active
     * @return {Section|null}
     */
    get currentSection() {
        let tableau=this.currentTableau;
        if(tableau){
            for(let s of tableau.sectionsData ){
                if(s.active){
                    return s;
                }
            }
        }
        return null;
    }

    /**
     * Renvoie la question active
     * @return {null|Question}
     */
    get currentQuestion() {
        let section=this.currentSection;
        if(section){
            for(let q of section.questions){
                if(q.isVisible){
                    return q;
                }
            }
        }
        return null;
    }

    keyEnterPressed(){
        switch (true){

            //passer l'animation texte
            case this.displayLoadingText && !this.isLoading:
                this.isLoadingTextComplete=true;
                break;

            //passer la map
            case this.isMap:
                this.startGame();
                break;

            //valider une question
            case this.currentQuestion && this.currentQuestion.isPlayerReponsePossible && this.currentQuestion.isVisible:
                this.currentQuestion.sendReponse();
                break;

            //passer une question terminée
            case this.currentQuestion && this.currentQuestion.isDisplayResult:
                this.currentQuestion.status=Question.STATUS_PASSED;
                break;
        }
    }

    /**
     * Déconnexion
     */
    logOut(){
        document.location.reload();
    }

}

