<template>
<div class="bulle-reponses" >
  <div class="content">
    <reponses :question="question"/>
  </div>
  <btn-check
      :active="question.isPlayerReponsePossible"
      @click.native="question.sendReponse()"
  />
</div>
</template>

<script>
import BtnCheck from "@/ui/btn-check";
import QuestionMixin from "@/game/questions/QuestionMixin";
import Reponses from "@/game/questions/components/reponses";
export default {
  name: "bulle-reponses",
  mixins:[QuestionMixin],
  components: {Reponses, BtnCheck}
}
</script>

<style lang="less">
.bulle-reponses{
  position: relative;
  background-color: #fff;
  border-radius: 40px;
  min-height: 40px;
  padding: 10px 30px;
  padding-right: 60px;
  border: 4px solid #eee;
  .content{
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .btn-check{
    position: absolute;
    right: -35px;
    border: 8px solid #fff;
    box-sizing: content-box;
    border-radius: 100%;
    top: calc(50%  - 35px - 4px)
  }
  &:after,&:before{
    position: absolute;
    left: 70px;
    top:100%;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 30px 0 0;
    border-color: #ffffff transparent transparent transparent;
  }
  &:before{
    border-color: #eee transparent transparent transparent;
    border-width: 35px 30px 0 0;
    transform: translateX(-6px);
  }
}
</style>